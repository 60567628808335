import React, { useState, useEffect } from 'react';
import { func, objectOf, any, bool } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Typo from '@material-ui/core/Typography';
import MobileFilterCategory from './mobileFilterCategory';
import MobileFilterTypes from './mobileFilterTypes';
import MobileFilterBrands from './mobileFilterBrands';
import MobileFilterMethods from './mobileFilterMethods';
import { FILTER_KEY, FILTER_LABELS } from '../../constants/filters';
import COLORS from '../../constants/colors';
import HomeGrid from '../HomeGrid';

const useStyles = makeStyles(
  ({ spacing, palette, typography, zIndex, shape, transitions }) => ({
    root: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: '100vh',
      zIndex: zIndex.tooltip,
      overflow: 'hidden',
      background: palette.background.paper,
      transition: transitions.easing.custom,
    },
    showRoot: {
      bottom: 0,
    },
    header: {
      position: 'relative',
      margin: spacing(3.5, 0, 2),
      width: '100%',
      textAlign: 'center',
    },
    title: {
      fontWeight: typography.fontWeightBold,
    },
    headCta: {
      position: 'absolute',
      top: '50%',
      right: spacing(2),
      transform: 'translateY(-50%)',
      fontSize: typography.body3.fontSize,
      color: palette.primary.main,
    },
    content: {
      marginLeft: spacing(2),
    },
    cta: {
      position: 'absolute',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '90%',
      bottom: spacing(1),
      left: '50%',
      transform: 'translateX(-50%)',
    },
    ctaHidden: {
      visibility: 'hidden',
    },
    submit: {
      background: palette.primary.main,
      width: '100%',
      textAlign: 'center',
      padding: spacing(1.5, 0),
      borderRadius: shape.round,
      color: COLORS.WHITE,
    },
    cancel: {
      color: palette.text.secondary,
      margin: spacing(1, 0),
      padding: spacing(1.5, 0),
    },
  }),
);

const MobileFilterList = ({
  filterConfigs,
  onFiltersObject,
  onClose,
  onClear,
  onSubmit,
  open,
}) => {
  const s = useStyles(undefined);
  const [ctaVisible, setCtaVisible] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const { CATEGORY, TYPE, BRAND, METHOD } = FILTER_KEY;
  const [toggleFilters, setToggleFilters] = useState({
    [CATEGORY]: false,
    [TYPE]: false,
    [BRAND]: false,
    [METHOD]: false,
  });
  const [forceFiltersClose, setForceFiltersClose] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setForceFiltersClose(false);
    }
  }, [isOpen]);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  useEffect(() => {
    setCtaVisible(
      Object.keys(toggleFilters).reduce((acc, key) => acc || toggleFilters[key], false),
    );
  }, [toggleFilters]);

  const handleToggleFilterList = key => value => {
    const defaultFilters = Object.keys(toggleFilters).reduce(
      (acc, item) => ({ ...acc, [item]: false }),
      {},
    );
    if (key) defaultFilters[key] = value;
    setToggleFilters(defaultFilters);
  };

  const handleSubmitFilters = () => {
    onSubmit();
  };

  const handleCloseFiltersList = () => {
    setForceFiltersClose(true);
    handleToggleFilterList()();
    onClose();
  };

  return (
    <HomeGrid className={classNames(s.root, isOpen && s.showRoot)}>
      <HomeGrid className={s.header}>
        <Typo className={s.title}>{FILTER_LABELS.LIST_TITLE}</Typo>
        <Typo onClick={handleCloseFiltersList} className={s.headCta}>
          {FILTER_LABELS.CTA.CLOSE_LIST}
        </Typo>
      </HomeGrid>
      <HomeGrid className={s.content}>
        <MobileFilterCategory
          categories={filterConfigs[CATEGORY]}
          isOpen={toggleFilters[CATEGORY]}
          onToggleFilters={handleToggleFilterList(CATEGORY)}
          onCategory={onFiltersObject}
          forceClose={forceFiltersClose}
        />
        <MobileFilterTypes
          types={filterConfigs[TYPE]}
          isOpen={toggleFilters[TYPE]}
          onToggleFilters={handleToggleFilterList(TYPE)}
          onTypes={onFiltersObject}
          forceClose={forceFiltersClose}
        />
        <MobileFilterBrands
          brands={filterConfigs[BRAND]}
          isOpen={toggleFilters[BRAND]}
          onToggleFilters={handleToggleFilterList(BRAND)}
          onBrands={onFiltersObject}
          forceClose={forceFiltersClose}
        />
        <MobileFilterMethods
          methods={filterConfigs[METHOD]}
          isOpen={toggleFilters[METHOD]}
          onToggleFilters={handleToggleFilterList(METHOD)}
          onMethods={onFiltersObject}
          forceClose={forceFiltersClose}
        />
      </HomeGrid>
      <HomeGrid className={classNames(s.cta, ctaVisible && s.ctaHidden)}>
        <HomeGrid className={s.submit} onClick={handleSubmitFilters}>
          <Typo variant="body2">{FILTER_LABELS.CTA.FILTER}</Typo>
        </HomeGrid>
        <HomeGrid className={s.cancel} onClick={onClear}>
          <Typo variant="body2">{FILTER_LABELS.CTA.DISMISS_ALL}</Typo>
        </HomeGrid>
      </HomeGrid>
    </HomeGrid>
  );
};

MobileFilterList.propTypes = {
  onClose: func.isRequired,
  onClear: func.isRequired,
  onSubmit: func.isRequired,
  onFiltersObject: func.isRequired,
  filterConfigs: objectOf(any).isRequired,
  open: bool,
};

MobileFilterList.defaultProps = {
  open: false,
};

export default MobileFilterList;
