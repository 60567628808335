/* eslint-disable max-len, no-undef, no-restricted-globals */
import React, { useEffect, useState, useRef } from 'react';
import { any, func, arrayOf, number, string, objectOf } from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Header from './header';
import Banner from './Banner';
import DesktopFilter from '../filter/desktopFilter';
import MobileFilter from '../filter/mobileFilter';
import MobileSearch from './mobileSearch';
import DesktopSearch from './desktopSearch';
import Loading from '../loading';
import Footer from '../footer';
import ContentDealArea from './contentdealarea';
import LAYOUT from '../../constants/layout';
import COLORS from '../../constants/colors';
import PROPS from './props';
import {
  DEFAULT_ID,
  FILTER_KEY,
  FILTERS_REGEXP,
  DEFAULT_LOCATION,
} from '../../constants/filters';
import { literalStringComparison } from '../../utils/string';
import useScrollPosition from '../../hooks/useScrollPosition';
import HomeGrid from '../HomeGrid';
import { addUrlQuery, repUrlQuery, sanitizeUrlQuery } from '../../utils/helpers';

const useStyles = makeStyles(
  ({ spacing, palette, zIndex, breakpoints, transitions }) => ({
    root: {
      width: '100%',
      margin: '0 auto',
      background: palette.background.default,
    },
    header: {
      position: 'fixed',
      top: 0,
      opacity: 1,
      zIndex: zIndex.appBar,
      boxShadow: COLORS.SHADOW_STANDARD,
      transition: transitions.easing.custom,
    },
    headerHidden: {
      opacity: 0,
      visibility: 'hidden',
    },
    deskFilter: {
      display: 'none',
    },
    mobFilter: {
      display: 'block',
      margin: `${spacing(3)}px auto 0`,
      padding: spacing(0, 1),
      height: spacing(6),
    },
    mobFilterFixed: {
      position: 'fixed',
      bottom: 0,
    },
    searchDesktop: {
      display: 'none',
    },
    searchMobile: {
      display: 'block',
      width: '100%',
    },
    [breakpoints.up(LAYOUT.RESPONSIVE_DESKTOP)]: {
      searchDesktop: {
        display: 'block',
        width: '100%',
        zIndex: zIndex.tooltip,
      },
      searchMobile: {
        display: 'none',
      },
      deskFilter: {
        display: 'block',
        padding: 'unset',
        height: spacing(17),
        border: '1px solid transparent',
      },
      mobFilter: {
        display: 'none',
      },
    },
  }),
);

const LandingPage = ({
  filter,
  configs,
  dealCount,
  searchDealResults,
  // refactor Mobile
  filteredSort,
  filteredWard,
  dispatchFilteredSort,
  dispatchSetUserDealsFiltered,
  dispatchSetFilteredItemsString,
  dispatchSetFilteredSortString,
  dispatchSetFilteredLocationString,
  dispatchSetFilteredWardString,
  filteredItemsString,
  filteredSortString,
  filteredLocationString,
  filteredWardString,
  dispatchPageNumber,
  dispatchFilteredLocation,
  dispatchFilteredWard,
  dispatchWardList,
}) => {
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up(LAYOUT.RESPONSIVE_DESKTOP));
  const s = useStyles();
  const [headerHidden, setHeaderHidden] = useState(false);
  const [initFilters, setInitFilters] = useState({});
  const [initLocation, setInitLocation] = useState([DEFAULT_LOCATION]);
  const [initWard, setInitWard] = useState([]);

  useScrollPosition(theme.spacing(LAYOUT.HEADER_SCROLL_TOP), setHeaderHidden);

  const { CATEGORY, TYPE, BRAND, METHOD, LOCATION: C_LOCATION } = FILTER_KEY;
  const { SORT, LOCATION, DISTRICT } = FILTERS_REGEXP;
  const [customFilteredSort, setCustomFilteredSort] = useState(LAYOUT.INIT_SORT);
  const [customFilteredWard, setCustomFilteredWard] = useState([]);
  const [filterConfigs, setFilterConfigs] = useState({});
  const filterView = useRef();
  const desktopView = useRef();
  const mobileView = useRef();

  const createFilterConfigs = ({ category, type, brand, method }) => ({
    [CATEGORY]: category,
    [TYPE]: type,
    [BRAND]: brand,
    [METHOD]: method.map(item => ({ ...item, selected: false })),
  });

  useEffect(() => {
    if (!isEmpty(configs)) {
      setFilterConfigs(createFilterConfigs(configs));
    }
  }, [configs]);

  const handleFilteredResult = fString => {
    const sanitizedUrl = sanitizeUrlQuery(fString);
    if (sanitizedUrl) {
      addUrlQuery(sanitizedUrl);
    } else {
      repUrlQuery('');
    }
    dispatchSetUserDealsFiltered(sanitizedUrl);
    dispatchPageNumber(LAYOUT.INIT_PAGE);
  };

  const handleClearAllFilters = () => {
    if (filteredItemsString || filteredLocationString || filteredSortString) {
      dispatchSetFilteredItemsString('');
      dispatchWardList([]);
      dispatchFilteredWard([]);
      dispatchFilteredLocation([DEFAULT_LOCATION]);
      dispatchFilteredSort(LAYOUT.INIT_SORT);
      dispatchSetFilteredSortString('');
      dispatchSetFilteredLocationString('');
      dispatchSetFilteredWardString('');
      handleFilteredResult('');
    }
  };

  useEffect(() => {
    handleClearAllFilters();
  }, []);

  useEffect(() => {
    const urlItems = filter
      .replace(/(\?)/, '')
      .replace(SORT, '')
      .replace(LOCATION, '')
      .replace(DISTRICT, '');
    dispatchSetFilteredItemsString(sanitizeUrlQuery(urlItems));
    const urlSort = filter.match(SORT);
    const urlLocation = filter.match(LOCATION);
    const urlWard = filter.match(DISTRICT);
    if (urlSort && urlSort[1]) {
      dispatchSetFilteredSortString(urlSort[0]);
      setCustomFilteredSort(Number(urlSort[1]));
      dispatchFilteredSort(Number(urlSort[1]));
    }
    if (urlLocation && urlLocation[1]) {
      dispatchSetFilteredLocationString(urlLocation[0]);
      const locationsList = get(configs, C_LOCATION, [DEFAULT_LOCATION]);
      const urlLocationItem = locationsList.find(item => item.id === urlLocation[1]);
      if (urlLocationItem && urlLocationItem.id) {
        dispatchFilteredLocation([urlLocationItem]);
        const wardList = get(urlLocationItem, 'wards', []);
        const wardListEnabled = wardList.length > 0;
        if (wardListEnabled && urlWard && urlWard[0]) {
          dispatchWardList(wardList);
          const urlWardItem = wardList.find(item => item.id === urlWard[1]);
          if (urlWardItem && urlWardItem.id) {
            dispatchSetFilteredWardString(urlWard[0]);
            dispatchFilteredWard([urlWardItem]);
          }
        }
      }
    }
    handleFilteredResult(filter);
  }, [configs, filter]);

  useEffect(() => {
    setCustomFilteredSort(filteredSort);
  }, [filteredSort]);

  useEffect(() => {
    setCustomFilteredWard(filteredWard);
  }, [filteredWard]);

  const headerStyle =
    !desktop && headerHidden ? `${s.header} ${s.headerHidden}` : s.header;

  const createFiltersString = (data = null) => {
    if (data) {
      return Object.keys(data).reduce((acc, key) => {
        const validString =
          data[key].length > 0 && data[key][0].id !== DEFAULT_ID
            ? `&${key}=${data[key].map(item => item.id).join(',')}`
            : '';
        return `${acc}${validString}`;
      }, '');
    }
    return '';
  };

  const handleSubmitFilters = data => {
    if (!literalStringComparison(data, initFilters)) {
      setInitFilters(data);
      const filterString = createFiltersString(data);
      dispatchSetFilteredItemsString(filterString);
      handleFilteredResult(
        `?${filterString}${filteredSortString}${filteredLocationString}${filteredWardString}`,
      );
    }
  };

  const handleSubmitFilterLocation = data => {
    if (!literalStringComparison(data, initLocation)) {
      setInitLocation(data);
      let submittedString = `?${filteredItemsString}${filteredSortString}`;
      if (get(data, '0.id', DEFAULT_ID) !== DEFAULT_ID) {
        const locationString = `&location=${get(data, '0.id')}`;
        dispatchSetFilteredLocationString(locationString);
        submittedString = `${submittedString}${locationString}`;
        const cachedWardId = get(customFilteredWard, '0.id', DEFAULT_ID);
        let wardString = '';
        if (cachedWardId !== DEFAULT_ID) {
          const liveWardList = get(data, 'wards', []);
          const liveWard =
            liveWardList.length > 0 &&
            liveWardList.find(item => item.id === cachedWardId);
          if (liveWard.id) {
            wardString = `&district=${get(customFilteredWard, '0.id')}`;
          }
          submittedString = `${submittedString}${wardString}`;
          dispatchSetFilteredWardString(wardString);
        }
      } else {
        dispatchSetFilteredLocationString('');
        dispatchSetFilteredWardString('');
      }
      handleFilteredResult(submittedString);
    }
  };

  const handleSubmitFilterWard = data => {
    if (!literalStringComparison(data, initWard)) {
      setInitWard(data);
      const wardId = get(data, '0.id', DEFAULT_ID);
      let submittedString = `?${filteredItemsString}${filteredSortString}${filteredLocationString}`;
      if (wardId !== DEFAULT_ID) {
        const wardString = `&district=${wardId}`;
        submittedString = `${submittedString}${wardString}`;
        dispatchSetFilteredWardString(wardString);
      }
      handleFilteredResult(submittedString);
    }
  };

  const handleSubmitFiltersSort = data => {
    const sortString = `&sort=${data}`;
    const submittedString = `?${filteredItemsString}${sortString}${filteredLocationString}${filteredWardString}`;
    dispatchFilteredSort(data);
    dispatchSetFilteredSortString(sortString);
    handleFilteredResult(submittedString);
  };

  const scrollInto = float => () => {
    if (!float) {
      filterView.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  };

  return (
    <>
      <HomeGrid className={s.root}>
        <HomeGrid
          fluid
          direction="column"
          justify="center"
          alignItems="center"
          className={headerStyle}
        >
          <Header />
          <HomeGrid item className={s.searchDesktop}>
            <DesktopSearch
              submitLocation={handleSubmitFilterLocation}
              submitWard={handleSubmitFilterWard}
              onHome={handleClearAllFilters}
            />
          </HomeGrid>
          <HomeGrid className={s.searchMobile}>
            <MobileSearch
              submitLocation={handleSubmitFilterLocation}
              submitWard={handleSubmitFilterWard}
              onHome={handleClearAllFilters}
            />
          </HomeGrid>
        </HomeGrid>
        <Banner theme={theme} bannerDeals={searchDealResults} />
        <HomeGrid ref={desktopView} fluid className={s.deskFilter}>
          <DesktopFilter
            ref={filterView}
            onScroll={scrollInto}
            filterConfigs={filterConfigs}
            onSubmit={handleSubmitFilters}
            submitClearFilters={handleClearAllFilters}
          />
        </HomeGrid>
        <HomeGrid ref={mobileView} className={s.mobFilter}>
          <MobileFilter
            filterConfigs={filterConfigs}
            sort={customFilteredSort}
            onSort={handleSubmitFiltersSort}
            onSubmit={handleSubmitFilters}
            submitClearFilters={handleClearAllFilters}
          />
        </HomeGrid>
        <ContentDealArea
          desktopView={desktopView}
          mobileView={mobileView}
          deals={searchDealResults}
          dealCount={dealCount}
          onSubmit={dispatchSetUserDealsFiltered}
          onSort={handleSubmitFiltersSort}
        />
        <Footer cta />
      </HomeGrid>
      <Loading />
    </>
  );
};

LandingPage.propTypes = {
  filter: string,
  dealCount: number,
  searchDealResults: arrayOf(any),
  configs: objectOf(any),
  filteredWard: arrayOf(any).isRequired,
  filteredSort: number,
  dispatchFilteredSort: func.isRequired,
  dispatchSetUserDealsFiltered: func.isRequired,
  dispatchSetFilteredItemsString: func.isRequired,
  dispatchSetFilteredSortString: func.isRequired,
  dispatchSetFilteredLocationString: func.isRequired,
  dispatchSetFilteredWardString: func.isRequired,
  dispatchPageNumber: func.isRequired,
  dispatchFilteredLocation: func.isRequired,
  dispatchFilteredWard: func.isRequired,
  dispatchWardList: func.isRequired,
  filteredItemsString: string,
  filteredSortString: string,
  filteredLocationString: string,
  filteredWardString: string,
};

LandingPage.defaultProps = {
  dealCount: 0,
  filter: '',
  configs: {},
  searchDealResults: [],
  filteredSort: 0,
  filteredItemsString: '',
  filteredSortString: '',
  filteredLocationString: '',
  filteredWardString: '',
};

export default connect(PROPS.mapStateToProps, PROPS.mapDispatchToProps)(LandingPage);
