import React, { useState, useEffect } from 'react';
import { arrayOf, any, func, bool, string } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import chunk from 'lodash/chunk';
import get from 'lodash/get';
import noop from 'lodash/noop';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import { uuid } from 'uuidv4';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Add from '@material-ui/icons/Add';
import Remove from '@material-ui/icons/Remove';
import {
  CATEGORY_ICONS,
  CATEGORY_BW,
  CATEGORY_CHUNK,
  FILTER_LABELS,
  DEFAULT_FILTER_ITEM,
  DEFAULT_ID,
  FILTERS_REGEXP,
  FILTER_KEY,
} from '../../constants/filters';
import COLORS from '../../constants/colors';
import { mobileFilters } from '../customer/props';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(0),
    background: theme.palette.background.paper,
  },
  items: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1, 5.5, 2, 0),
    margin: theme.spacing(2, 0, 0),
    borderBottom: `0.5px solid ${COLORS.LIGHT_GREY}`,
  },
  row: {
    margin: theme.spacing(1.5, 0),
    width: '100%',
  },
  filterItem: {
    width: '25%',
    padding: theme.spacing(0, 1),
  },
  normalItem: {
    cursor: 'pointer',
  },
  label: {
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.text.secondary,
    textAlign: 'center',
  },
  bold: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.text.primary,
  },
  icon: {
    position: 'absolute',
    right: theme.spacing(1.5),
    fill: theme.palette.primary.main,
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  iCat: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  content: {
    height: 0,
    opacity: 0,
    overflow: 'hidden',
    transition: theme.transitions.easing.custom,
  },
  showContent: {
    height: theme.spacing(48),
    opacity: 1,
  },
}));

const MobileFilterCategory = ({
  isOpen,
  categories,
  onCategory,
  onToggleFilters,
  filteredItemsString,
  forceClose,
}) => {
  const s = useStyles(undefined);
  const [currentCategory, setCurrentCategory] = useState([]);
  const [initCategory, setInitCategory] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [toggleCategories, setToggleCategories] = useState(isOpen);
  const [currentCategoryIndex, setCurrentCategoryIndex] = useState(0);
  const { CATEGORY } = FILTERS_REGEXP;
  const { CATEGORY: KEY_CATEGORY } = FILTER_KEY;

  useEffect(() => {
    const catPattern = filteredItemsString.match(CATEGORY);
    if (catPattern) {
      const category = categories.find(item => item.id === catPattern[1]);
      setCurrentCategory([category]);
      setInitCategory([category]);
      onCategory(KEY_CATEGORY, [category]);
    } else {
      setCurrentCategory([DEFAULT_FILTER_ITEM]);
    }
  }, [categories, filteredItemsString]);

  useEffect(() => {
    if (forceClose) {
      setCurrentCategory(initCategory);
      onCategory(initCategory);
    }
  }, [forceClose]);

  useEffect(() => {
    if (!isEmpty(categories)) {
      const currentCategoryName = get(currentCategory, '0.name', FILTER_LABELS.ALL);
      const list = [
        { name: FILTER_LABELS.ALL, id: DEFAULT_ID },
        ...categories,
        { name: '', id: 1, action: noop },
      ];
      const iList = list.map((item, index) => {
        const highLight = item.name === currentCategoryName;
        if (highLight) setCurrentCategoryIndex(index);

        return {
          ...item,
          icon: highLight ? CATEGORY_ICONS[index] : CATEGORY_BW[index],
        };
      });
      setCategoryList(chunk(iList, CATEGORY_CHUNK));
    }
  }, [categories, currentCategory]);

  useEffect(() => {
    setToggleCategories(isOpen);
  }, [isOpen]);

  const handleToggleCategories = () => {
    setToggleCategories(!toggleCategories);
    onToggleFilters(!toggleCategories);
  };

  const handleSelectCategory = (item, index) => () => {
    setCurrentCategory([item]);
    setCurrentCategoryIndex(index);
    handleToggleCategories();
    onCategory(KEY_CATEGORY, [item]);
  };

  return (
    <Grid component="div" item className={s.root}>
      <Grid component="div" item className={s.items} onClick={handleToggleCategories}>
        <Typography className={s.label}>{FILTER_LABELS.CATEGORY}</Typography>
        <Typography variant="body2" className={s.bold}>
          {get(currentCategory, '0.name', DEFAULT_FILTER_ITEM.name)}
        </Typography>
        {!toggleCategories && <Add className={s.icon} />}
        {toggleCategories && <Remove className={s.icon} />}
      </Grid>
      <Grid
        component="div"
        container
        direction="column"
        className={classNames(s.content, toggleCategories && s.showContent)}
      >
        {categoryList.map((row, rIndex) => (
          <Grid
            key={uuid()}
            component="div"
            container
            justify="space-between"
            className={s.row}
          >
            {row.map((item, index) => {
              const activeIndex = rIndex * CATEGORY_CHUNK + index;
              const activeLabel = currentCategoryIndex === activeIndex;
              const [action, itemStyle] =
                typeof item.action === 'function'
                  ? [noop, s.filterItem]
                  : [
                      handleSelectCategory(item, activeIndex),
                      classNames(s.filterItem, s.normalItem),
                    ];

              return (
                <Grid
                  key={activeIndex}
                  component="div"
                  item
                  className={itemStyle}
                  onClick={action}
                >
                  <Grid
                    component="div"
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                  >
                    <Grid component="div" item className={s.iconWrap}>
                      <item.icon className={s.iCat} />
                    </Grid>
                    <Typography className={classNames(s.label, activeLabel && s.bold)}>
                      {item.name}
                    </Typography>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

MobileFilterCategory.propTypes = {
  isOpen: bool,
  forceClose: bool,
  categories: arrayOf(any),
  onCategory: func.isRequired,
  onToggleFilters: func.isRequired,
  filteredItemsString: string.isRequired,
};

MobileFilterCategory.defaultProps = {
  isOpen: false,
  forceClose: false,
  categories: [],
};

export default connect(mobileFilters.mapStateToProps)(MobileFilterCategory);
