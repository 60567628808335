import React, { useState, useEffect } from 'react';
import { number, func, objectOf, any } from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import classNames from 'classnames';
import { connect } from 'react-redux';
import Typo from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Sort from '../../svgs/ic_sort.svg';
import Filter from '../../svgs/ic_filter.svg';
import MobileSort from './mobileSort';
import MobileFilterList from './mobileFilterList';
import COLORS from '../../constants/colors';
import { DEAL_SORT } from '../../constants/filters';
import { mobileFilters } from '../customer/props';
import useScrollPosition from '../../hooks/useScrollPosition';
import useScrollBottom from '../../hooks/useScrollBottom';
import LAYOUT from '../../constants/layout';
import HomeGrid from '../HomeGrid';

const useStyles = makeStyles(
  ({ spacing, palette, typography, zIndex, transitions }) => ({
    root: {
      margin: '0 auto',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      width: '100%',
      height: spacing(6),
      boxShadow: COLORS.SHADOW_STANDARD,
      borderRadius: spacing(7),
      background: palette.background.paper,
      transition: transitions.easing.custom,
      visibility: 'visible',
    },
    rootFixed: {
      position: 'fixed',
      margin: 0,
      left: 0,
      bottom: 0,
      boxShadow: 'unset',
      borderRadius: 'unset',
      zIndex: zIndex.tooltip,
      visibility: 'visible',
    },
    rootHidden: {
      visibility: 'hidden',
    },
    label: {
      fontSize: typography.body2.fontSize,
      fontWeight: typography.fontWeightBold,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '25%',
    },
    sortLabel: {
      width: 'auto',
      minWidth: '25%',
    },
    icon: {
      width: spacing(2.5),
      height: spacing(2.5),
      margin: spacing(0.25, 1, 0),
      fill: palette.primary.main,
    },
    verticalDivider: {
      height: spacing(3),
      background: COLORS.LIGHT_GREY,
      width: spacing(0.125),
      margin: spacing(0, 0.5),
    },
  }),
);

const MobileFilter = ({
  sort,
  onSort,
  onSubmit,
  filterConfigs,
  submitClearFilters,
}) => {
  const { spacing } = useTheme();
  const s = useStyles(undefined);
  const [toggleSort, setToggleSort] = useState(false);
  const [customSort, setCustomSort] = useState(sort);
  const [toggleFilterList, setToggleFilterList] = useState(false);
  const [mobileFilterFixed, setMobileFilterFixed] = useState(false);
  const [mobileFilterHidden, setMobileFilterHidden] = useState(false);
  const [filtersObject, setFiltersObject] = useState({});

  useScrollPosition(spacing(LAYOUT.MOBILE_FILTER_SCROLL_TOP), setMobileFilterFixed);
  useScrollBottom(spacing(LAYOUT.MOBILE_FOOTER_HEIGHT), setMobileFilterHidden);

  useEffect(() => {
    setCustomSort(sort);
  }, [sort]);

  const handleToggleFilterList = () => {
    setToggleFilterList(!toggleFilterList);
  };

  const handleToggleSort = () => {
    setToggleSort(!toggleSort);
  };

  const handleClearFilters = () => {
    submitClearFilters();
    setFiltersObject({});
    handleToggleFilterList();
  };

  const handleFiltersObject = (key, data) => {
    setFiltersObject({
      ...filtersObject,
      [key]: data,
    });
  };

  const handleSubmitFilters = () => {
    onSubmit(filtersObject);
    handleToggleFilterList();
  };

  return (
    <>
      <HomeGrid
        fluid
        justify="space-evenly"
        alignItems="center"
        className={classNames(
          s.root,
          mobileFilterFixed && s.rootFixed,
          mobileFilterHidden && s.rootHidden,
        )}
      >
        <Typo className={s.label} onClick={handleToggleFilterList}>
          Bộ lọc <Filter className={s.icon} />
        </Typo>
        <Divider component="hr" orientation="vertical" className={s.verticalDivider} />
        <Typo className={classNames(s.label, s.sortLabel)} onClick={handleToggleSort}>
          {DEAL_SORT[sort].label} <Sort className={s.icon} />
        </Typo>
      </HomeGrid>
      <MobileSort
        onClose={handleToggleSort}
        sort={customSort}
        onSort={onSort}
        open={toggleSort}
      />
      <MobileFilterList
        filterConfigs={filterConfigs}
        onFiltersObject={handleFiltersObject}
        onClose={handleToggleFilterList}
        onClear={handleClearFilters}
        onSubmit={handleSubmitFilters}
        open={toggleFilterList}
      />
    </>
  );
};

MobileFilter.propTypes = {
  sort: number,
  filterConfigs: objectOf(any),
  onSort: func.isRequired,
  onSubmit: func.isRequired,
  submitClearFilters: func.isRequired,
};

MobileFilter.defaultProps = {
  sort: 0,
  filterConfigs: {},
};

export default connect(mobileFilters.mapStateToProps)(MobileFilter);
