/* eslint-disable no-undef */
import React, { useState, useEffect, useRef } from 'react';
import { arrayOf, any, func, bool, string } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import IDown from '../../svgs/ic_down.svg';
import Search from '../../svgs/ic_search.svg';
import CheckedIcon from '../../svgs/ic-checked.svg';
import UncheckIcon from '../../svgs/ic-unchecked.svg';
import { deAccent } from '../../utils/string';
import COLORS from '../../constants/colors';
import LAYOUT from '../../constants/layout';
import {
  FILTER_LABELS,
  DEFAULT_FILTER_ITEM,
  FILTER_KEY,
  FILTERS_REGEXP,
} from '../../constants/filters';
import { mobileFilters } from '../customer/props';
import HomeGrid from '../HomeGrid';
import FilterCta from './filterCta';
import useClickOff from '../../hooks/useClickOff';

const useStyles = makeStyles(
  ({ spacing, typography, palette, shape, zIndex, transitions }) => ({
    root: {
      position: 'relative',
      margin: spacing(0),
      background: palette.background.paper,
      cursor: 'pointer',
    },
    items: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      padding: spacing(1),
    },
    row: {
      position: 'relative',
      width: '100%',
      padding: spacing(1, 0, 1, 4.5),
      minHeight: spacing(5),
    },
    label: {
      fontSize: typography.body1.fontSize,
      color: palette.text.secondary,
      textAlign: 'center',
      marginRight: spacing(1),
    },
    text: {
      width: spacing(7),
      textAlign: 'center',
    },
    bold: {
      fontWeight: typography.fontWeightBold,
      color: palette.text.primary,
    },
    icon: {
      fill: palette.primary.main,
      width: spacing(3),
      height: spacing(3),
      transition: transitions.easing.custom,
    },
    iActive: {
      transform: 'rotate(180deg)',
    },
    ibox: {
      position: 'absolute',
      top: '50%',
      left: spacing(2),
      transform: 'translateY(-50%)',
    },
    content: {
      position: 'absolute',
      flexDirection: 'column',
      bottom: '150%',
      left: 0,
      height: 0,
      opacity: 0,
      overflow: 'hidden',
      width: spacing(40),
      borderRadius: shape.rectRadius,
      background: palette.background.paper,
      boxShadow: COLORS.SHADOW_STANDARD,
      zIndex: zIndex.mobileStepper,
      transition: transitions.easing.custom,
    },
    showContent: {
      margin: spacing(1, 0),
      opacity: 1,
      height: spacing(59),
      maxHeight: spacing(59),
    },
    itemList: {
      display: 'flex',
      flexDirection: 'column',
      height: spacing(36),
      overflow: 'auto',
    },
    searchBox: {
      border: `0.5px solid ${COLORS.GREY}`,
      display: 'flex',
      alignItems: 'center',
      padding: spacing(0, 1),
      borderRadius: shape.rectRadius,
      margin: spacing(2),
    },
    iSearch: {
      width: spacing(3),
      height: spacing(3),
      margin: spacing(0, 1, 0, 1.5),
    },
    inputSearch: {
      outline: 'none',
      border: 'none',
      width: '85%',
      padding: spacing(1.5, 0),
      '&::placeholder': {
        fontSize: typography.body1.fontSize,
        color: COLORS.LIGHT_GREY,
      },
    },
    inputCheckbox: {
      visibility: 'hidden',
    },
  }),
);

const DesktopFilterBrands = ({
  brands,
  onBrands,
  isOpen,
  onToggleFilters,
  filteredItemsString,
  onSubmit,
  onClear,
}) => {
  const s = useStyles(undefined);
  const [brandList, setBrandList] = useState([]);
  const [toggleBrands, setToggleBrands] = useState(false);
  const [filteredLength, setFilteredLength] = useState(0);
  const [searchBrand, setSearchBrand] = useState('');
  const [searchResult, setSearchResult] = useState([]);
  const [currentBrands, setCurrentBrands] = useState([]);
  const [initBrands, setInitBrands] = useState([]);
  const { BRAND, DELIMITER } = FILTERS_REGEXP;
  const { BRAND: KEY_BRAND } = FILTER_KEY;
  const listRef = useRef();

  useEffect(() => {
    const brandPattern = filteredItemsString.match(BRAND);
    if (brandPattern) {
      const brandInit = brandPattern[1].split(DELIMITER);
      const brand = brands.filter(item => brandInit.includes(item.id));
      setCurrentBrands([...brand]);
      setInitBrands([...brand]);
      onBrands(KEY_BRAND, [...brand]);
      setFilteredLength(brand.length);
    } else {
      setCurrentBrands([]);
    }
  }, [brands, filteredItemsString]);

  useEffect(() => {
    if (!isEmpty(brands)) {
      let filteredBrandNames = [];
      if (!isEmpty(currentBrands)) {
        filteredBrandNames = currentBrands.map(item => item.id);
      }
      setFilteredLength(currentBrands.length);
      const list = [
        ...brands.map(item => ({
          ...item,
          selected: filteredBrandNames.includes(item.id),
        })),
      ];
      setBrandList(list);
      setSearchResult(list);
    }
  }, [brands, currentBrands]);

  useEffect(() => {
    setToggleBrands(isOpen);
  }, [isOpen]);

  const handleSearchBrand = evt => {
    const {
      target: { value },
    } = evt;
    setSearchBrand(value);
    if (value) {
      const searchPat = new RegExp(deAccent(value), 'i');
      setSearchResult([
        ...brandList.filter(item => searchPat.test(deAccent(item.name))),
      ]);
    } else {
      setSearchResult([...brandList]);
    }
  };

  const handleSelectBrand = id => () => {
    document.getElementById(id).click();
    setSearchBrand('');
  };

  const handleOnChange = evt => {
    const {
      target: { checked, name },
    } = evt;
    const index = brandList.map(item => item.id).indexOf(name);
    brandList[index].selected = checked;
    const selectedList = brandList.filter(item => item.selected);
    const selectAll = selectedList.length === brandList.length;
    setBrandList(
      selectAll
        ? [...brandList.map(item => ({ ...item, selected: false }))]
        : brandList,
    );
    const resolvedAll = selectAll ? [] : selectedList;
    setFilteredLength(resolvedAll.length);
    setCurrentBrands(resolvedAll);
    onBrands(KEY_BRAND, resolvedAll);
  };

  const handleToggleBrands = () => {
    setToggleBrands(!toggleBrands);
    onToggleFilters(!toggleBrands);
  };

  const handleClickOutside = () => {
    if (toggleBrands) {
      setToggleBrands(!toggleBrands);
      setCurrentBrands(initBrands);
      onBrands(KEY_BRAND, initBrands);
    }
  };

  useClickOff(listRef, handleClickOutside);

  const handleSubmitFilteredBrands = () => {
    onSubmit();
    setToggleBrands(!toggleBrands);
  };

  const handleClearFilters = () => {
    setToggleBrands(!toggleBrands);
    setFilteredLength(brands.length);
    onClear();
  };

  return (
    <HomeGrid className={s.root} ref={listRef}>
      <HomeGrid className={s.items} onClick={handleToggleBrands}>
        <Typography className={s.label}>{FILTER_LABELS.BRAND}</Typography>
        <Typography noWrap className={classNames(s.bold, s.text)}>
          {filteredLength > 0 && filteredLength < brandList.length
            ? `${filteredLength} ${FILTER_LABELS.FILTERED_BRANDS}`
            : DEFAULT_FILTER_ITEM.name}
        </Typography>
        <IDown className={classNames(s.icon, toggleBrands && s.iActive)} />
      </HomeGrid>
      <HomeGrid className={classNames(s.content, toggleBrands && s.showContent)}>
        <HomeGrid className={s.searchBox}>
          <Search className={s.iSearch} />
          <input
            placeholder={LAYOUT.SEARCH_BRAND_PLACEHOLDER}
            value={searchBrand}
            onChange={handleSearchBrand}
            className={s.inputSearch}
          />
        </HomeGrid>
        <HomeGrid className={s.itemList}>
          {searchResult.map(item => (
            <HomeGrid
              key={`desktop-${item.id}`}
              fluid
              className={s.row}
              onClick={handleSelectBrand(`desktop-${item.id}`)}
            >
              <HomeGrid>
                {item.selected && <CheckedIcon className={s.ibox} />}
                {!item.selected && <UncheckIcon className={s.ibox} />}
              </HomeGrid>
              <input
                id={`desktop-${item.id}`}
                name={item.id}
                type="checkbox"
                value={item.selected}
                checked={item.selected}
                onChange={handleOnChange}
                className={s.inputCheckbox}
              />
              <Typography>{item.name}</Typography>
            </HomeGrid>
          ))}
        </HomeGrid>
        <FilterCta
          onSubmit={handleSubmitFilteredBrands}
          onCancel={handleClearFilters}
        />
      </HomeGrid>
    </HomeGrid>
  );
};

DesktopFilterBrands.propTypes = {
  brands: arrayOf(any),
  onBrands: func.isRequired,
  onToggleFilters: func.isRequired,
  isOpen: bool,
  filteredItemsString: string.isRequired,
  onSubmit: func.isRequired,
  onClear: func.isRequired,
};

DesktopFilterBrands.defaultProps = {
  brands: [],
  isOpen: false,
};

export default connect(mobileFilters.mapStateToProps)(DesktopFilterBrands);
