import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Logo from '../svgs/logo_bw.svg';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(4, 0),
  },
  icon: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    fill: theme.palette.primary.dark,
  },
  text: {
    fontSize: theme.typography.body2.fontSize,
  },
}));

const EmptyItem = () => {
  const s = useStyles();
  useEffect(() => {}, []);

  return (
    <Grid
      component="div"
      container
      direction="column"
      justify="center"
      alignItems="center"
      className={s.root}
    >
      <Logo className={s.icon} />
      <Typography className={s.text}>Chưa có ưu đãi.</Typography>
    </Grid>
  );
};

EmptyItem.propTypes = {};

EmptyItem.defaultProps = {};

export default EmptyItem;
