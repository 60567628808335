import { useEffect } from 'react';

export default (callback, delay) => {
  useEffect(() => {
    const id = setInterval(callback, delay);
    return () => {
      clearInterval(id);
    };
  });
};
