import React from 'react';
import { func } from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import classNames from 'classnames';
import Typo from '@material-ui/core/Typography';
import HomeGrid from '../HomeGrid';
import { FILTER_LABELS } from '../../constants/filters';
import COLORS from '../../constants/colors';

const useStyles = makeStyles(({ spacing, palette, typography, shape }) => ({
  root: {
    margin: spacing(0),
    display: 'flex',
    flexDirection: 'column',
    padding: spacing(2),
  },
  button: {
    cursor: 'pointer',
    padding: spacing(1),
    fontSize: typography.body3.fontSize,
    borderRadius: shape.round,
    textAlign: 'center',
  },
  submit: {
    background: palette.primary.main,
    margin: `${spacing(2)}px 'auto'`,
    color: palette.primary.contrastText,
    marginBottom: spacing(1),
  },
  cancel: {
    background: COLORS.NONE,
  },
}));

const FilterCta = ({ onSubmit, onCancel }) => {
  const s = useStyles(undefined);
  const {
    CTA: { FILTER, DISMISS_ALL },
  } = FILTER_LABELS;

  return (
    <HomeGrid className={s.root}>
      <HomeGrid onClick={onSubmit}>
        <Typo className={classNames(s.button, s.submit)}>{FILTER}</Typo>
      </HomeGrid>
      <HomeGrid onClick={onCancel}>
        <Typo className={classNames(s.button, s.cancel)}>{DISMISS_ALL}</Typo>
      </HomeGrid>
    </HomeGrid>
  );
};

FilterCta.propTypes = {
  onSubmit: func.isRequired,
  onCancel: func.isRequired,
};

FilterCta.defaultProps = {};

export default FilterCta;
