import React, { useEffect, useState } from 'react';
import { arrayOf, any, bool } from 'prop-types';
import chunk from 'lodash/chunk';
import { uuid } from 'uuidv4';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import HomeGrid from '../HomeGrid';
import DealCard from './dealcard';
import LAYOUT from '../../constants/layout';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  panel: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    overflowX: 'auto',
    padding: spacing(1),
  },
}));

const DealPanel = ({ deals, large }) => {
  const [updateDeals, setUpdateDeals] = useState([]);
  const s = useStyles();
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up(LAYOUT.RESPONSIVE_DESKTOP));

  let factor = updateDeals.length;
  if (desktop) {
    if (large) {
      factor = LAYOUT.DEAL.LARGE_CHUNK;
    } else {
      factor = LAYOUT.DEAL.SMALL_CHUNK;
    }
  } else if (!large) {
    factor = 2;
  }
  const panelProps =
    !desktop && large
      ? {
          className: s.panel,
        }
      : {
          container: true,
          justify: 'center',
          alignItems: 'center',
        };

  useEffect(() => {
    setUpdateDeals(deals);
  }, [deals]);

  return (
    updateDeals.length > 0 && (
      <HomeGrid className={s.root}>
        {chunk(updateDeals, factor).map(row => {
          const rLength = row.length;
          const fullRow =
            rLength === factor
              ? [...row]
              : [
                  ...row,
                  ...Array.from({ length: factor - rLength }, () => ({
                    dummy: true,
                  })),
                ];
          return (
            <HomeGrid key={uuid()} {...panelProps}>
              {fullRow.map(item => (
                <DealCard key={uuid()} large={large} item={item} />
              ))}
            </HomeGrid>
          );
        })}
      </HomeGrid>
    )
  );
};

DealPanel.propTypes = {
  large: bool,
  deals: arrayOf(any).isRequired,
};

DealPanel.defaultProps = {
  large: false,
};

export default DealPanel;
