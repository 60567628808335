/* eslint-disable no-undef */
import { useEffect } from 'react';

export default (height, cb) => {
  const checkBottom = () => {
    const isBot =
      window.innerHeight + window.scrollY >= document.body.offsetHeight - height;
    cb(isBot);
  };

  useEffect(() => {
    document.addEventListener('scroll', checkBottom);
    return () => {
      document.removeEventListener('scroll', checkBottom);
    };
  });
};
