/*
  Dear maintainer:

  Once you are done trying to 'optimize' this web application,
  and have realized what a terrible mistake that was,
  please increment the following counter as a warning
  to the next guy:

  Total_hours_wasted_here = 0
 */

import React from 'react';
import { objectOf, any } from 'prop-types';
import get from 'lodash/get';
import Layout from '../components/layout';
import LandingPage from '../components/customer/landingpage';
import Loading from '../components/loading';
import Error from '../components/error';
import useConfigs from '../hooks/useConfigs';

const Index = ({ location }) => {
  const search = get(location, 'search');
  useConfigs();
  return (
    <>
      <Layout>
        <LandingPage filter={search} />
      </Layout>
      <Loading />
      <Error />
    </>
  );
};

Index.propTypes = {
  location: objectOf(any).isRequired,
};

export default Index;
