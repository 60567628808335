/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import { arrayOf, any, func, bool, string } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Add from '@material-ui/icons/Add';
import Remove from '@material-ui/icons/Remove';
import CheckedIcon from '../../svgs/ic-checked.svg';
import UncheckIcon from '../../svgs/ic-unchecked.svg';
import COLORS from '../../constants/colors';
import {
  FILTER_LABELS,
  DEFAULT_FILTER_ITEM,
  FILTER_KEY,
  FILTERS_REGEXP,
} from '../../constants/filters';
import { mobileFilters } from '../customer/props';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(0),
    background: theme.palette.background.paper,
  },
  items: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1, 5.5, 2, 0),
    margin: theme.spacing(2, 0, 0),
    borderBottom: `0.5px solid ${COLORS.LIGHT_GREY}`,
  },
  row: {
    position: 'relative',
    width: '100%',
    padding: theme.spacing(1, 2, 1, 0),
  },
  label: {
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.text.secondary,
    textAlign: 'center',
  },
  bold: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.text.primary,
  },
  icon: {
    position: 'absolute',
    right: theme.spacing(1.5),
    fill: theme.palette.primary.main,
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  ibox: {
    position: 'absolute',
    top: '50%',
    right: theme.spacing(2),
    transform: 'translateY(-50%)',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    height: 0,
    opacity: 0,
    overflow: 'hidden',
    transition: theme.transitions.easing.custom,
  },
  showContent: {
    margin: theme.spacing(1, 0),
    opacity: 1,
    height: theme.spacing(22),
    maxHeight: theme.spacing(22),
  },
  scrollList: {
    height: theme.spacing(22),
  },
  input: {
    visibility: 'hidden',
  },
}));

const MobileFilterTypes = ({
  types,
  onTypes,
  isOpen,
  onToggleFilters,
  filteredItemsString,
  forceClose,
}) => {
  const s = useStyles(undefined);
  const [typeList, setTypeList] = useState([]);
  const [toggleTypes, setToggleTypes] = useState(false);
  const [filteredLength, setFilteredLength] = useState(0);
  const [currentTypes, setCurrentTypes] = useState([]);
  const [initTypes, setInitTypes] = useState([]);
  const { TYPE, DELIMITER } = FILTERS_REGEXP;
  const { TYPE: KEY_TYPE } = FILTER_KEY;

  useEffect(() => {
    const typePattern = filteredItemsString.match(TYPE);
    if (typePattern) {
      const typesInit = typePattern[1].split(DELIMITER);
      const type = types.filter(item => typesInit.includes(item.id));
      setCurrentTypes([...type]);
      setInitTypes([...type]);
      onTypes(KEY_TYPE, [...type]);
      setFilteredLength(type.length);
    } else {
      setCurrentTypes([]);
    }
  }, [types, filteredItemsString]);

  useEffect(() => {
    if (forceClose) {
      setCurrentTypes(initTypes);
      onTypes(KEY_TYPE, initTypes);
    }
  }, [forceClose]);

  useEffect(() => {
    if (!isEmpty(types)) {
      const defaultTypes = [...types];
      let filteredTypeNames = [];
      if (!isEmpty(currentTypes)) {
        filteredTypeNames = currentTypes.map(item => item.id);
      }
      setFilteredLength(currentTypes.length);
      setTypeList([
        ...defaultTypes.map(item => ({
          ...item,
          selected: filteredTypeNames.includes(item.id),
        })),
      ]);
    }
  }, [types, currentTypes]);

  useEffect(() => {
    setToggleTypes(isOpen);
  }, [isOpen]);

  const handleSelectType = id => () => {
    document.getElementById(id).click();
  };

  const handleOnChange = evt => {
    const {
      target: { checked, name },
    } = evt;
    const index = typeList.map(item => item.id).indexOf(name);
    typeList[index].selected = checked;
    const selectedList = typeList.filter(item => item.selected);
    const selectAll = selectedList.length === typeList.length;
    setTypeList(
      selectAll ? [...typeList.map(item => ({ ...item, selected: false }))] : typeList,
    );
    const resolvedAll = selectAll ? [] : selectedList;
    setFilteredLength(resolvedAll.length);
    setCurrentTypes(resolvedAll);
    onTypes(KEY_TYPE, resolvedAll);
  };

  const handleToggleTypes = () => {
    setToggleTypes(!toggleTypes);
    onToggleFilters(!toggleTypes);
  };

  return (
    <Grid component="div" item className={s.root}>
      <Grid component="div" item className={s.items} onClick={handleToggleTypes}>
        <Typography className={s.label}>{FILTER_LABELS.TYPE}</Typography>
        <Typography variant="body2" className={s.bold}>
          {filteredLength > 0 && filteredLength < typeList.length
            ? `${filteredLength} ${FILTER_LABELS.FILTERED_TYPES}`
            : DEFAULT_FILTER_ITEM.name}
        </Typography>
        {!toggleTypes && <Add className={s.icon} />}
        {toggleTypes && <Remove className={s.icon} />}
      </Grid>
      <Grid
        component="div"
        className={classNames(s.content, toggleTypes && s.showContent)}
      >
        <Grid component="div" item className={s.scrollList}>
          {typeList.map(item => (
            <Grid
              key={item.id}
              component="div"
              container
              justify="space-between"
              className={s.row}
              onClick={handleSelectType(`mobile-${item.id}`)}
            >
              {item.name}
              <Grid component="div" item className={s.checkbox}>
                {item.selected && <CheckedIcon className={s.ibox} />}
                {!item.selected && <UncheckIcon className={s.ibox} />}
              </Grid>
              <input
                id={`mobile-${item.id}`}
                name={item.id}
                type="checkbox"
                value={item.selected}
                checked={item.selected}
                onChange={handleOnChange}
                className={s.input}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

MobileFilterTypes.propTypes = {
  types: arrayOf(any),
  onTypes: func.isRequired,
  onToggleFilters: func.isRequired,
  isOpen: bool,
  forceClose: bool,
  filteredItemsString: string.isRequired,
};

MobileFilterTypes.defaultProps = {
  types: [],
  isOpen: false,
  forceClose: false,
};

export default connect(mobileFilters.mapStateToProps)(MobileFilterTypes);
