/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import { arrayOf, any, func, bool, string } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import Typo from '@material-ui/core/Typography';
import Add from '@material-ui/icons/Add';
import Remove from '@material-ui/icons/Remove';
import Search from '../../svgs/ic_search.svg';
import CheckedIcon from '../../svgs/ic-checked.svg';
import UncheckIcon from '../../svgs/ic-unchecked.svg';
import HomeGrid from '../HomeGrid';
import { mobileFilters } from '../customer/props';
import { deAccent } from '../../utils/string';
import COLORS from '../../constants/colors';
import {
  FILTER_LABELS,
  DEFAULT_FILTER_ITEM,
  FILTER_KEY,
  FILTERS_REGEXP,
} from '../../constants/filters';

const useStyles = makeStyles(
  ({ spacing, palette, typography, shape, transitions }) => ({
    root: {
      margin: spacing(0),
      background: palette.background.paper,
    },
    items: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: spacing(1, 5.5, 2, 0),
      margin: spacing(2, 0, 0),
      borderBottom: `0.5px solid ${COLORS.LIGHT_GREY}`,
    },
    row: {
      position: 'relative',
      width: '100%',
      padding: spacing(1, 2, 1, 0),
    },
    label: {
      fontSize: typography.body2.fontSize,
      color: palette.text.secondary,
      textAlign: 'center',
    },
    bold: {
      fontWeight: typography.fontWeightBold,
      color: palette.text.primary,
    },
    icon: {
      position: 'absolute',
      right: spacing(1.5),
      fill: palette.primary.main,
      width: spacing(3),
      height: spacing(3),
    },
    ibox: {
      position: 'absolute',
      top: '50%',
      right: spacing(2),
      transform: 'translateY(-50%)',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      height: 0,
      overflow: 'hidden',
      opacity: 0,
      transition: transitions.easing.custom,
    },
    showContent: {
      height: spacing(46),
      margin: spacing(1, 0),
      overflow: 'auto',
      opacity: 1,
    },
    searchBox: {
      border: `0.5px solid ${COLORS.LIGHT_GREY}`,
      display: 'flex',
      alignItems: 'center',
      padding: spacing(1, 0),
      borderRadius: shape.round,
      margin: spacing(1, 2, 2, 0),
    },
    iSearch: {
      width: spacing(3),
      height: spacing(3),
      margin: spacing(0, 1, 0, 1.5),
    },
    inputSearch: {
      outline: 'none',
      border: 'none',
      width: '85%',
      '&::placeholder': {
        fontSize: typography.body2.fontSize,
        color: COLORS.LIGHT_GREY,
      },
    },
    inputCheckbox: {
      visibility: 'hidden',
    },
  }),
);

const MobileFilterBrands = ({
  brands,
  onBrands,
  isOpen,
  onToggleFilters,
  filteredItemsString,
  forceClose,
}) => {
  const s = useStyles();
  const [brandList, setBrandList] = useState([]);
  const [toggleBrands, setToggleBrands] = useState(false);
  const [filteredLength, setFilteredLength] = useState(0);
  const [searchBrand, setSearchBrand] = useState('');
  const [searchResult, setSearchResult] = useState([]);
  const [currentBrands, setCurrentBrands] = useState([]);
  const [initBrands, setInitBrands] = useState([]);
  const { BRAND, DELIMITER } = FILTERS_REGEXP;
  const { BRAND: KEY_BRAND } = FILTER_KEY;

  useEffect(() => {
    const brandPattern = filteredItemsString.match(BRAND);
    if (brandPattern) {
      const brandInit = brandPattern[1].split(DELIMITER);
      const brand = brands.filter(item => brandInit.includes(item.id));
      setCurrentBrands([...brand]);
      setInitBrands([...brand]);
      onBrands(KEY_BRAND, [...brand]);
      setFilteredLength(brand.length);
    } else {
      setCurrentBrands([]);
    }
  }, [brands, filteredItemsString]);

  useEffect(() => {
    if (forceClose) {
      setCurrentBrands(initBrands);
      onBrands(KEY_BRAND, initBrands);
    }
  }, [forceClose]);

  useEffect(() => {
    if (!isEmpty(brands)) {
      let filteredBrandNames = [];
      if (!isEmpty(currentBrands)) {
        filteredBrandNames = currentBrands.map(item => item.id);
      }
      setFilteredLength(currentBrands.length);
      const list = [
        ...brands.map(item => ({
          ...item,
          selected: filteredBrandNames.includes(item.id),
        })),
      ];
      setBrandList(list);
      setSearchResult(list);
    }
  }, [brands, currentBrands]);

  useEffect(() => {
    setToggleBrands(isOpen);
  }, [isOpen]);

  const handleSearchBrand = evt => {
    const {
      target: { value },
    } = evt;
    setSearchBrand(value);
    if (value) {
      const searchPat = new RegExp(deAccent(value), 'i');
      setSearchResult([
        ...brandList.filter(item => searchPat.test(deAccent(item.name))),
      ]);
    } else {
      setSearchResult([...brandList]);
    }
  };

  const handleSelectBrand = id => () => {
    document.getElementById(id).click();
    setSearchBrand('');
  };

  const handleOnChange = evt => {
    const {
      target: { checked, name },
    } = evt;
    const index = brandList.map(item => item.id).indexOf(name);
    brandList[index].selected = checked;
    const selectedList = brandList.filter(item => item.selected);
    const selectAll = selectedList.length === brandList.length;
    setBrandList(
      selectAll
        ? [...brandList.map(item => ({ ...item, selected: false }))]
        : brandList,
    );
    const resolvedAll = selectAll ? [] : selectedList;
    setFilteredLength(resolvedAll.length);
    setCurrentBrands(resolvedAll);
    onBrands(KEY_BRAND, resolvedAll);
  };

  const handleToggleBrands = () => {
    setToggleBrands(!toggleBrands);
    onToggleFilters(!toggleBrands);
  };

  return (
    <HomeGrid className={s.root}>
      <HomeGrid className={s.items} onClick={handleToggleBrands}>
        <Typo className={s.label}>{FILTER_LABELS.BRAND}</Typo>
        <Typo variant="body2" className={s.bold}>
          {filteredLength > 0 && filteredLength < brandList.length
            ? `${filteredLength} ${FILTER_LABELS.FILTERED_BRANDS}`
            : DEFAULT_FILTER_ITEM.name}
        </Typo>
        {!toggleBrands && <Add className={s.icon} />}
        {toggleBrands && <Remove className={s.icon} />}
      </HomeGrid>
      <HomeGrid className={classNames(s.content, toggleBrands && s.showContent)}>
        <HomeGrid className={s.searchBox}>
          <Search className={s.iSearch} />
          <input
            placeholder="Tìm thương hiệu"
            value={searchBrand}
            onChange={handleSearchBrand}
            className={s.inputSearch}
          />
        </HomeGrid>
        {searchResult.map(item => (
          <HomeGrid
            key={`mobile-${item.id}`}
            fluid
            justify="space-between"
            className={s.row}
            onClick={handleSelectBrand(`mobile-${item.id}`)}
          >
            {item.name}
            <HomeGrid className={s.checkbox}>
              {item.selected && <CheckedIcon className={s.ibox} />}
              {!item.selected && <UncheckIcon className={s.ibox} />}
            </HomeGrid>
            <input
              id={`mobile-${item.id}`}
              name={item.id}
              type="checkbox"
              value={item.selected}
              checked={item.selected}
              onChange={handleOnChange}
              className={s.inputCheckbox}
            />
          </HomeGrid>
        ))}
      </HomeGrid>
    </HomeGrid>
  );
};

MobileFilterBrands.propTypes = {
  brands: arrayOf(any),
  onBrands: func.isRequired,
  onToggleFilters: func.isRequired,
  isOpen: bool,
  forceClose: bool,
  filteredItemsString: string.isRequired,
};

MobileFilterBrands.defaultProps = {
  brands: [],
  isOpen: false,
  forceClose: false,
};

export default connect(mobileFilters.mapStateToProps)(MobileFilterBrands);
