/* eslint-disable no-undef */
import { useEffect } from 'react';

export default (position, cb) => {
  const handleScroll = () => {
    const scrollPosition =
      window.pageYOffset ||
      window.scrollY ||
      document.body.scrollTop +
        ((document.documentElement && document.documentElement.scrollTop) || 0);
    cb(scrollPosition > position);
  };
  useEffect(() => {
    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);
};
