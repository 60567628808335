import React, { useState, useEffect } from 'react';
import { arrayOf, any, objectOf } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Link } from '@reach/router';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import findIndex from 'lodash/findIndex';
import isEqual from 'lodash/isEqual';
import Fade from '@material-ui/core/Fade';
import Typo from '@material-ui/core/Typography';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Divider from '@material-ui/core/Divider';
import CustomImage from '../customImage';
import HomeGrid from '../HomeGrid';
import resolveImageUrl from '../../utils/image';
import { dateDeadline } from '../../utils/helpers';
import { trim } from '../../utils/string';
import useInterval from '../../hooks/useInterval';
import LAYOUT from '../../constants/layout';
import COLORS from '../../constants/colors';
import ROUTER from '../../constants/router';
import TIME from '../../constants/time';

const useStyles = makeStyles(
  ({ spacing, palette, shape, breakpoints, typography }) => ({
    root: {
      margin: '0 auto',
      maxWidth: spacing(LAYOUT.MAX_WIDTH_BANNER),
      width: '100%',
      position: 'relative',
      height: spacing(31),
      marginTop: spacing(LAYOUT.APP_BAR_HEIGHT),
      background: palette.background.paper,
      overflow: 'hidden',
    },
    pictureMain: {
      height: '100%',
      objectFit: 'cover',
    },
    expire: {
      display: 'flex',
    },
    slideContent: {
      position: 'absolute',
      bottom: spacing(2),
    },
    expireText: {
      fontSize: typography.body2.fontSize,
      color: COLORS.WHITE,
    },
    expireItem: {
      border: `0.5px solid ${COLORS.LIGHT_GREY}`,
      borderRadius: shape.rectRadius,
      padding: spacing(0.25, 1),
      margin: spacing(0, 0.5),
      background: 'rgba(0, 0, 0, 0.3)',
      boxShadow: COLORS.SHADOW_STANDARD,
    },
    title: {
      margin: spacing(2.5, 0, 1),
      display: 'flex',
      alignItems: 'center',
      fontSize: spacing(3),
      fontWeight: typography.fontWeightBold,
      color: COLORS.WHITE,
      textShadow: COLORS.TEXT_SHADOW,
    },
    brandWrap: {
      margin: spacing(0, 0, 1),
    },
    logoWrap: {
      width: spacing(5),
      height: spacing(5),
      borderRadius: shape.round,
      background: palette.background.paper,
      overflow: 'hidden',
      objectFit: 'cover',
    },
    logo: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    brand: {
      fontSize: typography.body3.fontSize,
      fontWeight: typography.fontWeightBold,
      textTransform: 'uppercase',
      color: COLORS.WHITE,
      textShadow: COLORS.TEXT_SHADOW,
      margin: spacing(0, 1.5),
    },
    divider: {
      width: 2,
      height: 16,
      background: COLORS.WHITE,
    },
    viewDetail: {
      display: 'flex',
    },
    detail: {
      fontSize: typography.body2.fontSize,
      color: COLORS.WHITE,
      textShadow: COLORS.TEXT_SHADOW,
      margin: spacing(0, 0.5, 0, 1.5),
      '&:hover': {
        cursor: 'pointer',
        textShadow: COLORS.TEXT_SHADOW,
      },
    },
    icon: {
      paddingTop: spacing(0.5),
      width: spacing(4),
      height: spacing(4),
      fill: palette.primary.main,
    },
    slidePictureWrap: {
      margin: spacing(1),
      width: spacing(1.3),
      height: spacing(1.3),
      borderRadius: shape.round,
      overflow: 'hidden',
      boxShadow: COLORS.SHADOW_STANDARD,
      background: palette.background.paper,
    },
    slidePicture: {
      width: '100%',
      height: '100%',
      cursor: 'pointer',
      objectFit: 'cover',
    },
    slideActive: {
      background: palette.primary.dark,
    },
    link: {
      textDecoration: 'none',
      display: 'flex',
      alignItems: 'center',
    },
    [breakpoints.up(LAYOUT.RESPONSIVE_DESKTOP)]: {
      root: {
        height: spacing(LAYOUT.MAX_HEIGHT_BANNER),
      },
      slideContent: {
        bottom: spacing(5),
      },
      title: {
        fontSize: typography.h4.fontSize,
        margin: spacing(2.5, 0, 0),
      },
      brandWrap: {
        margin: spacing(0, 0, 4),
      },
      logoWrap: {
        width: spacing(7),
        height: spacing(7),
        borderRadius: spacing(15),
        background: palette.background.paper,
      },
      brand: {
        fontSize: typography.body2.fontSize,
      },
      icon: {
        width: spacing(3.5),
        height: spacing(3.5),
        fill: palette.primary.main,
      },
      slidePictureWrap: {
        margin: spacing(1, 1.5, 0),
        width: spacing(18),
        height: spacing(8),
        borderRadius: shape.rectRadius,
        transition: LAYOUT.TRANSITION_EASING,
        '&:hover': {
          transform: 'scale(1.25)',
        },
        boxShadow: COLORS.SHADOW_MEDIUM,
      },
    },
  }),
);

const Banner = ({ theme, bannerDeals }) => {
  const s = useStyles();
  const desktop = useMediaQuery(theme.breakpoints.up(LAYOUT.RESPONSIVE_DESKTOP));
  const [topDealsDisplay, setTopDealsDisplay] = useState([]);
  const [displayDeal, setDisplayDeal] = useState({});
  const [dealIndex, setDealIndex] = useState(0);

  useEffect(() => {
    if (Array.isArray(bannerDeals) && !isEmpty(bannerDeals)) {
      const topFourDeals = bannerDeals.slice(0, LAYOUT.DEAL.TOP);
      setDisplayDeal(topFourDeals[0]);
      setTopDealsDisplay(topFourDeals);
      setDealIndex(0);
    }
  }, [bannerDeals]);

  const dealTitle = get(displayDeal, 'title');
  const duration = get(displayDeal, 'expire', '');
  const showDeadLine = moment(duration).diff(moment.now(), 'd') < TIME.EXPIRED_WARN;
  const expires = dateDeadline(get(displayDeal, 'expire', '')).split(':');
  const logo = get(displayDeal, 'brand.logo.url', '');
  const brand = get(displayDeal, 'brand.name', '');
  const slug = get(displayDeal, 'slug.name');
  const pictureUrl = get(displayDeal, 'picture.url');
  const enableSlideShow = topDealsDisplay.length > 0;
  const slideInterval = enableSlideShow ? LAYOUT.BANNER_SLIDE_SHOW_INTERVAL : 0;

  const handleNextDeal = () => {
    const maxIndex = topDealsDisplay.length;
    const currentIndex = dealIndex < maxIndex - 1 ? dealIndex + 1 : 0;
    setDealIndex(currentIndex);
    setDisplayDeal(topDealsDisplay[currentIndex]);
  };

  useInterval(handleNextDeal, slideInterval);

  const handleSelectDeal = deal => () => {
    setDisplayDeal(deal);
    setDealIndex(
      findIndex(topDealsDisplay, item => isEqual(get(item, 'id'), get(deal, 'id'))),
    );
  };

  return (
    <Fade in timeout={TIME.EFFECT_TIMEOUT}>
      <HomeGrid fluid direction="column" className={s.root}>
        <CustomImage
          src={resolveImageUrl(pictureUrl)}
          alt={slug}
          className={s.pictureMain}
        />
        <HomeGrid
          fluid
          direction="column"
          alignItems="center"
          className={s.slideContent}
        >
          {showDeadLine && (
            <HomeGrid fluid justify="center" alignItems="center">
              {expires.map((item, index) => {
                const colon = index < 2 ? ':' : '';
                return (
                  <HomeGrid key={item} className={s.expire}>
                    <Typo className={`${s.expireText} ${s.expireItem}`}>{item}</Typo>
                    <Typo className={s.expireText}>{colon}</Typo>
                  </HomeGrid>
                );
              })}
            </HomeGrid>
          )}
          <Typo className={s.title}>
            {trim(
              dealTitle,
              desktop ? LAYOUT.CARD.TITLE_LENGTH_LARGE : LAYOUT.CARD.TITLE_LENGTH_MD,
            )}
            {!desktop && slug && (
              <Link to={`${ROUTER.CUSTOMER.DEAL_DETAIL}/${slug}`} className={s.link}>
                <ChevronRight className={s.icon} />
              </Link>
            )}
          </Typo>
          <HomeGrid fluid justify="center" alignItems="center" className={s.brandWrap}>
            <HomeGrid className={s.logoWrap}>
              <CustomImage
                logo
                src={resolveImageUrl(logo)}
                alt={brand}
                className={s.logo}
              />
            </HomeGrid>
            <Typo className={s.brand}>{brand}</Typo>
            {desktop && slug && (
              <>
                <Divider
                  component="hr"
                  orientation="vertical"
                  light
                  className={s.divider}
                />
                <HomeGrid className={s.viewDetail}>
                  <Link
                    to={`${ROUTER.CUSTOMER.DEAL_DETAIL}/${slug}`}
                    className={s.link}
                  >
                    <Typo className={s.detail}>Xem chi tiết</Typo>
                    <ChevronRight className={s.icon} />
                  </Link>
                </HomeGrid>
              </>
            )}
          </HomeGrid>
          <HomeGrid fluid justify="center" alignItems="center">
            {!isEmpty(topDealsDisplay) &&
              topDealsDisplay.map((item, ind) => {
                const wrapStyle =
                  ind === dealIndex
                    ? `${s.slidePictureWrap} ${s.slideActive}`
                    : s.slidePictureWrap;
                return (
                  <HomeGrid
                    key={item.id}
                    className={wrapStyle}
                    onClick={handleSelectDeal(item)}
                  >
                    {desktop && (
                      <CustomImage
                        src={resolveImageUrl(get(item, 'picture.url'))}
                        alt={item.name}
                        className={s.slidePicture}
                      />
                    )}
                  </HomeGrid>
                );
              })}
          </HomeGrid>
        </HomeGrid>
      </HomeGrid>
    </Fade>
  );
};

Banner.propTypes = {
  bannerDeals: arrayOf(any),
  theme: objectOf(any).isRequired,
};

Banner.defaultProps = {
  bannerDeals: [],
};

export default Banner;
