import React, { useState, forwardRef } from 'react';
import { func, objectOf, any } from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import classNames from 'classnames';
import HomeGrid from '../HomeGrid';
import DesktopFilterCategory from './desktopFilterCategory';
import DesktopFilterTypes from './desktopFilterTypes';
import DesktopFilterBrands from './desktopFilterBrands';
import DesktopFilterMethods from './desktopFilterMethods';
import { FILTER_KEY } from '../../constants/filters';
import COLORS from '../../constants/colors';
import LAYOUT from '../../constants/layout';
import useScrollPosition from '../../hooks/useScrollPosition';
import useScrollBottom from '../../hooks/useScrollBottom';

const useStyles = makeStyles(({ spacing, palette, zIndex }) => ({
  root: {
    margin: `${spacing(3)}px auto 0`,
    height: spacing(9),
    borderRadius: spacing(6),
    background: palette.background.paper,
    boxShadow: COLORS.SHADOW_STANDARD,
    width: '100%',
    maxWidth: spacing(LAYOUT.MAX_WIDTH),
    visibility: 'visible',
  },
  rootFloat: {
    position: 'fixed',
    bottom: spacing(8),
    left: '50%',
    transform: 'translateX(-50%)',
    minWidth: spacing(LAYOUT.FILTER_FLOAT_WIDTH),
    zIndex: zIndex.tooltip,
    visibility: 'visible',
  },
  rootHidden: {
    visibility: 'hidden',
  },
  filter: {
    maxWidth: '30%',
  },
  divider: {
    height: '60%',
    width: 1,
  },
}));

const DesktopFilter = forwardRef((props, ref) => {
  const theme = useTheme();
  const { onScroll, filterConfigs, onSubmit, submitClearFilters } = props;
  const s = useStyles(undefined);
  const { CATEGORY, TYPE, BRAND, METHOD } = FILTER_KEY;
  const [toggleFilters, setToggleFilters] = useState({
    [CATEGORY]: false,
    [TYPE]: false,
    [BRAND]: false,
    [METHOD]: false,
  });
  const [floatFilter, setFloatFilter] = useState(false);
  const [hideFilter, setHideFilter] = useState(false);
  const [filtersObject, setFiltersObject] = useState({});

  useScrollPosition(theme.spacing(LAYOUT.FILTER_FLOAT_POSITION), setFloatFilter);
  useScrollBottom(theme.spacing(LAYOUT.FOOTER_HEIGHT), setHideFilter);

  const handleToggleFilters = key => value => {
    const defaultFilters = Object.keys(toggleFilters).reduce(
      (acc, item) => ({ ...acc, [item]: false }),
      {},
    );
    if (key) defaultFilters[key] = value;
    setToggleFilters(defaultFilters);
    onScroll(floatFilter)();
  };

  const handleFilterObject = (key, data) => {
    setFiltersObject({
      ...filtersObject,
      [key]: data,
    });
  };

  const handleSubmitFilters = () => {
    onSubmit(filtersObject);
  };

  const handleSubmitCategory = data => {
    onSubmit({
      ...filtersObject,
      [CATEGORY]: data,
    });
  };

  const handleClearFilters = () => {
    setFiltersObject({});
    submitClearFilters();
  };

  return (
    <HomeGrid
      ref={ref}
      fluid
      justify="space-evenly"
      alignItems="center"
      className={classNames(
        s.root,
        floatFilter && s.rootFloat,
        hideFilter && s.rootHidden,
      )}
    >
      <HomeGrid className={s.filter}>
        <DesktopFilterCategory
          categories={filterConfigs[CATEGORY]}
          isOpen={toggleFilters[CATEGORY]}
          onToggleFilters={handleToggleFilters(CATEGORY)}
          onCategory={handleFilterObject}
          onSubmit={handleSubmitCategory}
        />
      </HomeGrid>
      <Divider className={s.divider} />
      <HomeGrid className={s.filter}>
        <DesktopFilterTypes
          types={filterConfigs[TYPE]}
          isOpen={toggleFilters[TYPE]}
          onToggleFilters={handleToggleFilters(TYPE)}
          onTypes={handleFilterObject}
          onSubmit={handleSubmitFilters}
          onClear={handleClearFilters}
        />
      </HomeGrid>
      <Divider className={s.divider} />
      <HomeGrid className={s.filter}>
        <DesktopFilterBrands
          brands={filterConfigs[BRAND]}
          isOpen={toggleFilters[BRAND]}
          onToggleFilters={handleToggleFilters(BRAND)}
          onBrands={handleFilterObject}
          onSubmit={handleSubmitFilters}
          onClear={handleClearFilters}
        />
      </HomeGrid>
      <Divider className={s.divider} />
      <HomeGrid className={s.filter}>
        <DesktopFilterMethods
          methods={filterConfigs[METHOD]}
          isOpen={toggleFilters[METHOD]}
          onToggleFilters={handleToggleFilters(METHOD)}
          onMethods={handleFilterObject}
          onSubmit={handleSubmitFilters}
          onClear={handleClearFilters}
        />
      </HomeGrid>
    </HomeGrid>
  );
});

DesktopFilter.propTypes = {
  filterConfigs: objectOf(any),
  onSubmit: func.isRequired,
  submitClearFilters: func.isRequired,
  onScroll: func.isRequired,
};

DesktopFilter.defaultProps = {
  filterConfigs: {},
};

export default DesktopFilter;
