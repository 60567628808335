import React, { useState, useEffect, useRef } from 'react';
import { arrayOf, any, func, bool, string } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import chunk from 'lodash/chunk';
import get from 'lodash/get';
import noop from 'lodash/noop';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import { uuid } from 'uuidv4';
import Typography from '@material-ui/core/Typography';
import IDown from '../../svgs/ic_down.svg';
import {
  CATEGORY_ICONS,
  CATEGORY_BW,
  CATEGORY_CHUNK,
  FILTER_LABELS,
  DEFAULT_FILTER_ITEM,
  DEFAULT_ID,
  FILTERS_REGEXP,
  FILTER_KEY,
} from '../../constants/filters';
import HomeGrid from '../HomeGrid';
import useClickOff from '../../hooks/useClickOff';
import { mobileFilters } from '../customer/props';
import COLORS from '../../constants/colors';

const useStyles = makeStyles(
  ({ spacing, palette, typography, zIndex, shape, transitions }) => ({
    root: {
      margin: spacing(0),
      background: palette.background.paper,
      cursor: 'pointer',
      position: 'relative',
    },
    items: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: spacing(1),
    },
    row: {
      margin: spacing(1.5, 0),
      width: '100%',
    },
    filterItem: {
      width: '25%',
      padding: spacing(0, 1),
    },
    normalItem: {
      cursor: 'pointer',
    },
    label: {
      fontSize: typography.body1.fontSize,
      color: palette.text.secondary,
      textAlign: 'center',
      marginRight: spacing(1),
    },
    text: {
      width: spacing(9),
      textAlign: 'center',
    },
    bold: {
      fontWeight: typography.fontWeightBold,
      color: palette.text.primary,
    },
    mediumBold: {
      fontWeight: typography.fontWeightMedium,
      color: palette.text.primary,
    },
    icon: {
      fill: palette.primary.main,
      width: spacing(3),
      height: spacing(3),
      transition: transitions.easing.custom,
    },
    iActive: {
      transform: 'rotate(180deg)',
    },
    iCat: {
      width: spacing(5),
      height: spacing(5),
    },
    content: {
      position: 'absolute',
      bottom: '170%',
      left: 0,
      height: 0,
      opacity: 0,
      overflow: 'hidden',
      width: spacing(55),
      borderRadius: shape.rectRadius,
      background: palette.background.paper,
      boxShadow: COLORS.SHADOW_STANDARD,
      zIndex: zIndex.mobileStepper,
      transition: transitions.easing.custom,
    },
    showContent: {
      height: spacing(55),
      minHeight: spacing(55),
      opacity: 1,
      padding: spacing(2),
    },
  }),
);

const DesktopFilterCategory = ({
  isOpen,
  categories,
  onCategory,
  onToggleFilters,
  filteredItemsString,
  onSubmit,
}) => {
  const s = useStyles(undefined);
  const [currentCategory, setCurrentCategory] = useState([]);
  const [initCategory, setInitCategory] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [toggleCategories, setToggleCategories] = useState(isOpen);
  const [currentCategoryIndex, setCurrentCategoryIndex] = useState(0);
  const { CATEGORY } = FILTERS_REGEXP;
  const { CATEGORY: KEY_CATEGORY } = FILTER_KEY;
  const listRef = useRef();

  useEffect(() => {
    const catPattern = filteredItemsString.match(CATEGORY);
    if (catPattern) {
      const category = categories.find(item => item.id === catPattern[1]);
      setCurrentCategory([category]);
      setInitCategory([category]);
      onCategory(KEY_CATEGORY, [category]);
    } else {
      setCurrentCategory([DEFAULT_FILTER_ITEM]);
    }
  }, [categories, filteredItemsString]);

  useEffect(() => {
    if (!isEmpty(categories)) {
      const currentCategoryName = get(currentCategory, '0.name', FILTER_LABELS.ALL);
      const list = [
        { name: FILTER_LABELS.ALL, id: DEFAULT_ID },
        ...categories,
        { name: '', id: 1, action: noop },
      ];
      const iList = list.map((item, index) => {
        const highLight = item.name === currentCategoryName;
        if (highLight) setCurrentCategoryIndex(index);

        return {
          ...item,
          icon: highLight ? CATEGORY_ICONS[index] : CATEGORY_BW[index],
        };
      });
      setCategoryList(chunk(iList, CATEGORY_CHUNK));
    }
  }, [categories, currentCategory]);

  useEffect(() => {
    setToggleCategories(isOpen);
  }, [isOpen]);

  const handleToggleCategories = () => {
    setToggleCategories(!toggleCategories);
    onToggleFilters(!toggleCategories);
  };

  const handleClickOutside = () => {
    if (toggleCategories) {
      setToggleCategories(!toggleCategories);
      setCurrentCategory(initCategory);
    }
  };

  useClickOff(listRef, handleClickOutside);

  const handleSelectCategory = (item, index) => () => {
    setCurrentCategory([item]);
    setCurrentCategoryIndex(index);
    handleToggleCategories();
    onSubmit([item]);
    onCategory(KEY_CATEGORY, [item]);
  };

  return (
    <HomeGrid className={s.root} ref={listRef}>
      <HomeGrid className={s.items} onClick={handleToggleCategories}>
        <Typography className={s.label}>{FILTER_LABELS.CATEGORY}</Typography>
        <Typography noWrap className={classNames(s.bold, s.text)}>
          {get(currentCategory, '0.name', DEFAULT_FILTER_ITEM.name)}
        </Typography>
        <IDown className={classNames(s.icon, toggleCategories && s.iActive)} />
      </HomeGrid>
      <HomeGrid
        fluid
        direction="column"
        className={classNames(s.content, toggleCategories && s.showContent)}
      >
        {categoryList.map((row, rIndex) => (
          <HomeGrid key={uuid()} fluid justify="space-between" className={s.row}>
            {row.map((item, index) => {
              const activeIndex = rIndex * CATEGORY_CHUNK + index;
              const activeLabel = currentCategoryIndex === activeIndex;
              const [action, itemStyle] =
                typeof item.action === 'function'
                  ? [noop, s.filterItem]
                  : [
                      handleSelectCategory(item, activeIndex),
                      classNames(s.filterItem, s.normalItem),
                    ];

              return (
                <HomeGrid key={activeIndex} className={itemStyle} onClick={action}>
                  <HomeGrid
                    fluid
                    direction="column"
                    justify="center"
                    alignItems="center"
                  >
                    <HomeGrid className={s.iconWrap}>
                      <item.icon className={s.iCat} />
                    </HomeGrid>
                    <Typography
                      className={classNames(s.label, activeLabel && s.mediumBold)}
                    >
                      {item.name}
                    </Typography>
                  </HomeGrid>
                </HomeGrid>
              );
            })}
          </HomeGrid>
        ))}
      </HomeGrid>
    </HomeGrid>
  );
};

DesktopFilterCategory.propTypes = {
  isOpen: bool,
  categories: arrayOf(any),
  onCategory: func.isRequired,
  onToggleFilters: func.isRequired,
  onSubmit: func.isRequired,
  filteredItemsString: string.isRequired,
};

DesktopFilterCategory.defaultProps = {
  isOpen: false,
  categories: [],
};

export default connect(mobileFilters.mapStateToProps)(DesktopFilterCategory);
