/* eslint-disable no-undef */
import React, { useState, useEffect, useRef } from 'react';
import { arrayOf, any, func, bool, string } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IDown from '../../svgs/ic_down.svg';
import CheckedIcon from '../../svgs/ic-checked.svg';
import UncheckIcon from '../../svgs/ic-unchecked.svg';
import {
  FILTER_LABELS,
  DEFAULT_FILTER_ITEM,
  FILTER_KEY,
  FILTERS_REGEXP,
} from '../../constants/filters';
import { mobileFilters } from '../customer/props';
import HomeGrid from '../HomeGrid';
import COLORS from '../../constants/colors';
import useClickOff from '../../hooks/useClickOff';
import FilterCta from './filterCta';

const useStyles = makeStyles(
  ({ spacing, typography, shape, zIndex, palette, transitions }) => ({
    root: {
      position: 'relative',
      margin: spacing(0),
      background: palette.background.paper,
      cursor: 'pointer',
    },
    items: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      padding: spacing(1),
    },
    row: {
      position: 'relative',
      width: '100%',
      padding: spacing(1, 0, 1, 7),
    },
    label: {
      fontSize: typography.body1.fontSize,
      color: palette.text.secondary,
      textAlign: 'center',
      marginRight: spacing(1),
    },
    text: {
      width: spacing(7),
      textAlign: 'center',
    },
    bold: {
      fontWeight: typography.fontWeightBold,
      color: palette.text.primary,
    },
    icon: {
      fill: palette.primary.main,
      width: spacing(3),
      height: spacing(3),
      transition: transitions.easing.custom,
    },
    iActive: {
      transform: 'rotate(180deg)',
    },
    ibox: {
      position: 'absolute',
      top: '50%',
      left: spacing(2),
      transform: 'translateY(-50%)',
    },
    content: {
      position: 'absolute',
      bottom: '150%',
      left: 0,
      display: 'flex',
      flexDirection: 'column',
      height: 0,
      opacity: 0,
      overflow: 'hidden',
      width: spacing(40),
      borderRadius: shape.rectRadius,
      background: palette.background.paper,
      boxShadow: COLORS.SHADOW_STANDARD,
      zIndex: zIndex.mobileStepper,
      transition: transitions.easing.custom,
    },
    showContent: {
      margin: spacing(1, 0),
      opacity: 1,
      height: spacing(34),
      maxHeight: spacing(34),
      paddingTop: spacing(2),
    },
    scrollList: {
      height: spacing(18),
    },
    input: {
      visibility: 'hidden',
      height: 0,
      width: 0,
      overflow: 'hidden',
    },
  }),
);

const DesktopFilterMethods = ({
  methods,
  onMethods,
  isOpen,
  onToggleFilters,
  filteredItemsString,
  onSubmit,
  onClear,
}) => {
  const s = useStyles(undefined);
  const [methodList, setMethodList] = useState([]);
  const [toggleMethods, setToggleMethods] = useState(false);
  const [filteredLength, setFilteredLength] = useState(0);
  const [currentMethods, setCurrentMethods] = useState([]);
  const [initMethods, setInitMethods] = useState([]);
  const { METHOD, DELIMITER } = FILTERS_REGEXP;
  const { METHOD: KEY_METHOD } = FILTER_KEY;
  const listRef = useRef();

  useEffect(() => {
    const methodPattern = filteredItemsString.match(METHOD);
    if (methodPattern) {
      const methodsInit = methodPattern[1].split(DELIMITER);
      const method = methods.filter(item => methodsInit.includes(item.id));
      setCurrentMethods([...method]);
      setInitMethods([...method]);
      onMethods(KEY_METHOD, [...method]);
      setFilteredLength(method.length);
    } else {
      setCurrentMethods([]);
    }
  }, [methods, filteredItemsString]);

  useEffect(() => {
    if (!isEmpty(methods)) {
      const defaultMethods = [...methods];
      let filteredMethodNames = [];
      if (!isEmpty(currentMethods)) {
        filteredMethodNames = currentMethods.map(item => item.id);
      }
      setFilteredLength(currentMethods.length);
      setMethodList([
        ...defaultMethods.map(item => ({
          ...item,
          selected: filteredMethodNames.includes(item.id),
        })),
      ]);
    }
  }, [methods, currentMethods]);

  useEffect(() => {
    setToggleMethods(isOpen);
  }, [isOpen]);

  const handleSelectMethod = id => () => {
    document.getElementById(id).click();
  };

  const handleOnChange = evt => {
    const {
      target: { checked, name },
    } = evt;
    const index = methodList.map(item => item.id).indexOf(name);
    methodList[index].selected = checked;
    const selectedList = methodList.filter(item => item.selected);
    const selectAll = selectedList.length === methodList.length;
    setMethodList(
      selectAll
        ? [...methodList.map(item => ({ ...item, selected: false }))]
        : methodList,
    );
    const resolvedAll = selectAll ? [] : selectedList;
    setFilteredLength(resolvedAll.length);
    setCurrentMethods(resolvedAll);
    onMethods(KEY_METHOD, resolvedAll);
  };

  const handleToggleMethods = () => {
    setToggleMethods(!toggleMethods);
    onToggleFilters(!toggleMethods);
  };

  const handleClickOutside = () => {
    if (toggleMethods) {
      setToggleMethods(!toggleMethods);
      setCurrentMethods(initMethods);
      onMethods(KEY_METHOD, initMethods);
    }
  };

  useClickOff(listRef, handleClickOutside);

  const handleSubmitFilteredMethods = () => {
    onSubmit();
    setToggleMethods(!toggleMethods);
  };

  const handleClearFilters = () => {
    setToggleMethods(!toggleMethods);
    setFilteredLength(methods.length);
    onClear();
  };

  return (
    <HomeGrid className={s.root} ref={listRef}>
      <HomeGrid className={s.items} onClick={handleToggleMethods}>
        <Typography className={s.label}>{FILTER_LABELS.METHOD}</Typography>
        <Typography noWrap className={classNames(s.bold, s.text)}>
          {filteredLength > 0 && filteredLength < methodList.length
            ? `${filteredLength} ${FILTER_LABELS.FILTERED_METHODS}`
            : DEFAULT_FILTER_ITEM.name}
        </Typography>
        <IDown className={classNames(s.icon, toggleMethods && s.iActive)} />
      </HomeGrid>
      <HomeGrid className={classNames(s.content, toggleMethods && s.showContent)}>
        <HomeGrid component="div" item className={s.scrollList}>
          {methodList.map(item => (
            <HomeGrid
              key={`desktop-${item.id}`}
              fluid
              className={s.row}
              onClick={handleSelectMethod(`desktop-${item.id}`)}
            >
              <Typography>{item.name}</Typography>
              <Grid component="div" item className={s.checkbox}>
                {item.selected && <CheckedIcon className={s.ibox} />}
                {!item.selected && <UncheckIcon className={s.ibox} />}
              </Grid>
              <input
                id={`desktop-${item.id}`}
                name={item.id}
                type="checkbox"
                value={item.selected}
                checked={item.selected}
                onChange={handleOnChange}
                className={s.input}
              />
            </HomeGrid>
          ))}
        </HomeGrid>
        <FilterCta
          onSubmit={handleSubmitFilteredMethods}
          onCancel={handleClearFilters}
        />
      </HomeGrid>
    </HomeGrid>
  );
};

DesktopFilterMethods.propTypes = {
  methods: arrayOf(any),
  onMethods: func.isRequired,
  onToggleFilters: func.isRequired,
  isOpen: bool,
  onSubmit: func.isRequired,
  onClear: func.isRequired,
  filteredItemsString: string.isRequired,
};

DesktopFilterMethods.defaultProps = {
  methods: [],
  isOpen: false,
};

export default connect(mobileFilters.mapStateToProps)(DesktopFilterMethods);
