/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { arrayOf, objectOf, any, number, func, string } from 'prop-types';
import { connect } from 'react-redux';
import chunk from 'lodash/chunk';
import isEmpty from 'lodash/isEmpty';
import { uuid } from 'uuidv4';
import classNames from 'classnames';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ChevronRight from '@material-ui/icons/ChevronRight';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import Typography from '@material-ui/core/Typography';
import DealPanel from '../deal/dealpanel';
import EmptyItem from '../emptyItem';
import PROPS from './props';
import LAYOUT from '../../constants/layout';
import COLOR from '../../constants/colors';
import { FILTERS_REGEXP } from '../../constants/filters';
import SortBar from '../sortBar';
import HomeGrid from '../HomeGrid';

const useStyles = makeStyles(({ spacing, palette, breakpoints, typography }) => ({
  root: {
    margin: '0 auto',
    maxWidth: spacing(LAYOUT.MAX_WIDTH),
  },
  header: {
    margin: spacing(0, 0, 2.5),
  },
  offerText: {
    display: 'none',
  },
  icon: {
    width: spacing(3.5),
    height: spacing(3.5),
    fill: palette.primary.main,
    cursor: 'pointer',
  },
  iconDisabled: {
    fill: COLOR.NONE,
  },
  pagingWrap: {
    margin: `${spacing(4)}px auto ${spacing(6)}px`,
    userSelect: 'none',
  },
  paging: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: spacing(25),
  },
  pageIndication: {
    width: spacing(2),
    margin: spacing(0, 0.5),
    cursor: 'pointer',
    color: COLOR.GREY,
    fontWeight: typography.fontWeightBold,
  },
  pageIndicationActive: {
    color: COLOR.DARK_GREY,
  },
  [breakpoints.up(LAYOUT.RESPONSIVE_DESKTOP)]: {
    header: {
      margin: spacing(2.2, 0, 2.5),
    },
    offerText: {
      display: 'block',
      fontSize: typography.body3.fontSize,
      fontWeight: typography.fontWeightBold,
    },
  },
}));

const ContentDealArea = ({
  pageNumber,
  deals,
  dealCount,
  onSort,
  onSubmit,
  desktopView,
  mobileView,
  dispatchPageNumber,
  filteredItemsString,
  filteredSortString,
  filteredLocationString,
  filteredWardString,
}) => {
  const s = useStyles();
  const { breakpoints } = useTheme();
  const [topDeals, setTopDeals] = useState([]);
  const [totalDeal, setTotalDeal] = useState(0);
  const [usualDeals, setUsualDeals] = useState([]);
  const [pageChunks, setPageChunks] = useState(0);
  const [chunkIndex, setChunkIndex] = useState(0);
  const [pageIndexActive, setPageIndexActive] = useState(LAYOUT.INIT_PAGE);
  const [showPaging, setShowPaging] = useState(true);
  const [sortActive, setSortActive] = useState(LAYOUT.INIT_SORT);
  const showDeals = deals.length > 0;
  const { SORT } = FILTERS_REGEXP;
  const desktop = useMediaQuery(breakpoints.up(LAYOUT.RESPONSIVE_DESKTOP));

  useEffect(() => {
    const sort = filteredSortString.match(SORT);
    setSortActive((sort && Number(sort[1])) || LAYOUT.INIT_SORT);
  }, [filteredSortString]);

  useEffect(() => {
    setPageIndexActive(pageNumber);
  }, [pageNumber]);

  useEffect(() => {
    if (Array.isArray(deals) && !isEmpty(deals)) {
      setTopDeals(deals.slice(0, LAYOUT.DEAL.TOP));
      setUsualDeals(deals.slice(LAYOUT.DEAL.TOP));
    }
  }, [deals]);

  useEffect(() => {
    const pages = Array.from(
      { length: Math.ceil(dealCount / LAYOUT.PAGE_ITEMS) },
      (_, index) => index,
    );
    const chunkFactor = pages.length > LAYOUT.PAGING ? LAYOUT.PAGING : pages.length;
    const chunks = chunk(pages, chunkFactor);
    setPageChunks(chunks);
    setTotalDeal(dealCount);
    setShowPaging(dealCount > 10);
  }, [dealCount]);

  const handleViewTopPage = () => {
    if (desktop) {
      desktopView.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else {
      mobileView.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };
  const handleChunkIndex = value => () => {
    let index;
    if (value) {
      index = chunkIndex < pageChunks.length - 1 ? chunkIndex + 1 : chunkIndex;
      setChunkIndex(index);
    } else {
      index = chunkIndex > 0 ? chunkIndex - 1 : chunkIndex;
      setChunkIndex(index);
    }
    const activeIndex = index * LAYOUT.PAGING + 1;
    let submittedString = `?${filteredItemsString}${filteredSortString}${filteredLocationString}${filteredWardString}&page=${activeIndex}`;
    submittedString = submittedString.replace('?&', '?');
    setPageIndexActive(activeIndex);
    onSubmit(submittedString);
    handleViewTopPage();
  };

  const handleSelectPage = page => () => {
    if (page !== pageNumber) {
      setPageIndexActive(page);
      dispatchPageNumber(page);
      let submittedString = `?${filteredItemsString}${filteredSortString}${filteredLocationString}${filteredWardString}&page=${page}`;
      submittedString = submittedString.replace('?&', '?');
      onSubmit(submittedString);
      handleViewTopPage();
    }
  };

  const handleSelectSort = key => () => {
    setSortActive(key);
    onSort(key);
  };

  const createPagingCta = list => (
    <HomeGrid fluid justify="space-evenly" alignItems="center">
      {list.length > 0 &&
        list.map(item => {
          const displayPage = Number(item) + 1;

          return (
            <HomeGrid
              key={uuid()}
              className={classNames(
                s.pageIndication,
                pageIndexActive === displayPage && s.pageIndicationActive,
              )}
              onClick={handleSelectPage(displayPage)}
            >
              {displayPage}
            </HomeGrid>
          );
        })}
    </HomeGrid>
  );

  return (
    <HomeGrid type="section" fluid direction="column" className={s.root}>
      <HomeGrid fluid justify="space-between" alignItems="center" className={s.header}>
        <HomeGrid>
          <Typography className={s.offerText}>
            {totalDeal} ƯU ĐÃI DÀNH CHO BẠN
          </Typography>
        </HomeGrid>
        <HomeGrid>
          <SortBar active={sortActive} onSort={handleSelectSort} />
        </HomeGrid>
      </HomeGrid>
      <HomeGrid fluid direction="column" justify="space-between" alignItems="center">
        {showDeals && (
          <>
            <DealPanel large deals={topDeals} />
            <DealPanel deals={usualDeals} />
          </>
        )}
        {!showDeals && <EmptyItem />}
      </HomeGrid>
      <HomeGrid className={s.pagingWrap}>
        {pageChunks.length > 0 && pageChunks[chunkIndex] && (
          <HomeGrid className={s.paging}>
            {showPaging && (
              <>
                <ChevronLeft
                  className={classNames(s.icon, chunkIndex === 0 && s.iconDisabled)}
                  onClick={handleChunkIndex(false)}
                />
                {createPagingCta(pageChunks[chunkIndex])}
                <ChevronRight
                  className={classNames(
                    s.icon,
                    pageChunks.length - 1 === chunkIndex && s.iconDisabled,
                  )}
                  onClick={handleChunkIndex(true)}
                />
              </>
            )}
          </HomeGrid>
        )}
      </HomeGrid>
    </HomeGrid>
  );
};

ContentDealArea.propTypes = {
  dealCount: number,
  pageNumber: number,
  deals: arrayOf(any),
  desktopView: objectOf(any),
  mobileView: objectOf(any),
  onSubmit: func.isRequired,
  onSort: func.isRequired,
  dispatchPageNumber: func.isRequired,
  filteredItemsString: string,
  filteredSortString: string,
  filteredLocationString: string,
  filteredWardString: string,
};

ContentDealArea.defaultProps = {
  deals: [],
  dealCount: 0,
  desktopView: {},
  mobileView: {},
  pageNumber: LAYOUT.INIT_PAGE,
  filteredItemsString: '',
  filteredSortString: '',
  filteredLocationString: '',
  filteredWardString: '',
};

export default connect(
  PROPS.mapStateToProps,
  PROPS.mapDispatchToProps,
)(ContentDealArea);
