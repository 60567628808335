import React, { useState, useEffect } from 'react';
import { func, number, bool } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Tick from '../../svgs/ic_tick.svg';
import COLORS from '../../constants/colors';
import { DEAL_SORT } from '../../constants/filters';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: '100vh',
    width: '100%',
    overflow: 'hidden',
    transition: theme.transitions.easing.custom,
    background: theme.palette.background.paper,
    zIndex: theme.zIndex.tooltip,
  },
  showRoot: {
    bottom: 0,
  },
  header: {
    position: 'relative',
    margin: theme.spacing(3.5, 0, 2),
    width: '100%',
    textAlign: 'center',
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
  },
  icon: {
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
  },
  headCta: {
    position: 'absolute',
    top: '50%',
    right: theme.spacing(2),
    transform: 'translateY(-50%)',
    fontSize: theme.typography.body3.fontSize,
    color: theme.palette.primary.main,
  },
  content: {
    marginLeft: theme.spacing(2),
  },
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2, 2, 2, 0),
    borderBottom: `1px solid ${COLORS.LIGHT_GREY}`,
  },
  textItem: {
    fontSize: theme.typography.body2.fontSize,
  },
  activeItem: {
    fontWeight: theme.typography.fontWeightBold,
  },
}));

const MobileSort = ({ onClose, sort, onSort, open }) => {
  const s = useStyles(undefined);
  const [sortKey, setSortKey] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  useEffect(() => {
    setSortKey(sort);
  }, [sort]);

  const handleSortKey = key => () => {
    onClose();
    onSort(key);
    setSortKey(key);
  };

  return (
    <Grid component="div" item className={classNames(s.root, isOpen && s.showRoot)}>
      <Grid component="div" item className={s.header}>
        <Typography className={s.title}>Sắp xếp ưu đãi</Typography>
        <Typography onClick={onClose} className={s.headCta}>
          ĐÓNG
        </Typography>
      </Grid>
      <Grid component="div" item className={s.content}>
        {DEAL_SORT.map(item => (
          <Grid
            key={item.key}
            component="div"
            item
            className={s.item}
            onClick={handleSortKey(item.key)}
          >
            <Typography
              className={`${s.textItem} ${sortKey === item.key && s.activeItem}`}
            >
              {item.label}
            </Typography>
            {sortKey === item.key && <Tick className={s.icon} />}
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

MobileSort.propTypes = {
  onClose: func.isRequired,
  onSort: func.isRequired,
  sort: number,
  open: bool,
};

MobileSort.defaultProps = {
  sort: 0,
  open: false,
};

export default MobileSort;
