import React, { useState, useEffect } from 'react';
import { func, number } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { uuid } from 'uuidv4';
import { DEAL_SORT } from '../constants/filters';
import COLORS from '../constants/colors';
import LAYOUT from '../constants/layout';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'none',
  },
  [theme.breakpoints.up(LAYOUT.RESPONSIVE_DESKTOP)]: {
    root: {
      display: 'flex',
      position: 'relative',
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    sortTitle: {
      padding: theme.spacing(0.5, 3),
      margin: theme.spacing(0, 1),
      background: COLORS.SMOKE,
      borderRadius: theme.spacing(25),
      cursor: 'pointer',
      border: 'unset',
    },
    sortActive: {
      background: COLORS.BLACK,
      color: COLORS.WHITE,
    },
    sortText: {
      fontSize: theme.typography.body3.fontSize,
      color: theme.palette.text.secondary,
      textTransform: 'uppercase',
    },
    sortTextActive: {
      color: COLORS.WHITE,
      fontWeight: 'unset',
    },
  },
}));

const SortBar = ({ active, onSort }) => {
  const s = useStyles();
  const [sortActive, setSortActive] = useState(active);

  useEffect(() => {
    setSortActive(active);
  }, [active]);

  return (
    <Grid component="div" className={s.root}>
      {DEAL_SORT.map((item, index) => {
        const sortWrapStyle =
          index === sortActive ? `${s.sortTitle} ${s.sortActive}` : s.sortTitle;
        const sortStyle =
          index === sortActive ? `${s.sortText} ${s.sortTextActive}` : s.sortText;

        return (
          <Grid
            key={uuid()}
            component="div"
            item
            className={sortWrapStyle}
            onClick={onSort(index)}
          >
            <Typography className={sortStyle}>{item.label}</Typography>
          </Grid>
        );
      })}
    </Grid>
  );
};

SortBar.propTypes = {
  active: number,
  onSort: func.isRequired,
};

SortBar.defaultProps = {
  active: 0,
};

export default SortBar;
