/* eslint-disable no-undef */
import React, { useState, useEffect, useRef } from 'react';
import { arrayOf, any, func, bool, string } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import IDown from '../../svgs/ic_down.svg';
import CheckedIcon from '../../svgs/ic-checked.svg';
import UncheckIcon from '../../svgs/ic-unchecked.svg';
import {
  FILTER_LABELS,
  DEFAULT_FILTER_ITEM,
  FILTER_KEY,
  FILTERS_REGEXP,
} from '../../constants/filters';
import { mobileFilters } from '../customer/props';
import HomeGrid from '../HomeGrid';
import COLORS from '../../constants/colors';
import useClickOff from '../../hooks/useClickOff';
import FilterCta from './filterCta';

const useStyles = makeStyles(
  ({ spacing, palette, typography, shape, zIndex, transitions }) => ({
    root: {
      position: 'relative',
      margin: spacing(0),
      background: palette.background.paper,
      cursor: 'pointer',
    },
    items: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      padding: spacing(1),
    },
    row: {
      position: 'relative',
      width: '100%',
      padding: spacing(1, 0, 1, 7),
    },
    label: {
      fontSize: typography.body1.fontSize,
      color: palette.text.secondary,
      textAlign: 'center',
      marginRight: spacing(1),
    },
    text: {
      width: spacing(7),
      textAlign: 'center',
    },
    bold: {
      fontWeight: typography.fontWeightBold,
      color: palette.text.primary,
    },
    icon: {
      fill: palette.primary.main,
      width: spacing(3),
      height: spacing(3),
      transition: transitions.easing.custom,
    },
    iActive: {
      transform: 'rotate(180deg)',
    },
    ibox: {
      position: 'absolute',
      top: '50%',
      left: spacing(2),
      transform: 'translateY(-50%)',
    },
    content: {
      position: 'absolute',
      flexDirection: 'column',
      bottom: '150%',
      left: 0,
      height: 0,
      opacity: 0,
      overflow: 'hidden',
      width: spacing(40),
      borderRadius: shape.rectRadius,
      background: palette.background.paper,
      boxShadow: COLORS.SHADOW_STANDARD,
      zIndex: zIndex.mobileStepper,
      transition: transitions.easing.custom,
    },
    showContent: {
      margin: spacing(1, 0),
      opacity: 1,
      height: spacing(40),
      maxHeight: spacing(40),
      paddingTop: spacing(2),
    },
    scrollList: {
      height: spacing(24),
    },
    input: {
      visibility: 'hidden',
    },
  }),
);

const DesktopFilterTypes = ({
  types,
  onTypes,
  isOpen,
  onSubmit,
  onClear,
  onToggleFilters,
  filteredItemsString,
}) => {
  const s = useStyles(undefined);
  const [typeList, setTypeList] = useState([]);
  const [toggleTypes, setToggleTypes] = useState(false);
  const [filteredLength, setFilteredLength] = useState(0);
  const [currentTypes, setCurrentTypes] = useState([]);
  const [initTypes, setInitTypes] = useState([]);
  const [typeLabel, setTypeLabel] = useState(DEFAULT_FILTER_ITEM.name);
  const { TYPE, DELIMITER } = FILTERS_REGEXP;
  const { TYPE: KEY_TYPE } = FILTER_KEY;
  const listRef = useRef();

  useEffect(() => {
    const typePattern = filteredItemsString.match(TYPE);
    if (typePattern) {
      const typesInit = typePattern[1].split(DELIMITER);
      const type = types.filter(item => typesInit.includes(item.id));
      setCurrentTypes([...type]);
      setInitTypes([...type]);
      onTypes(KEY_TYPE, [...type]);
      setFilteredLength(type.length);
    } else {
      setCurrentTypes([]);
    }
  }, [types, filteredItemsString]);

  useEffect(() => {
    if (!isEmpty(types)) {
      const defaultTypes = [...types];
      let filteredTypeNames = [];
      if (!isEmpty(currentTypes)) {
        filteredTypeNames = currentTypes.map(item => item.id);
      }
      setFilteredLength(currentTypes.length);
      setTypeList([
        ...defaultTypes.map(item => ({
          ...item,
          selected: filteredTypeNames.includes(item.id),
        })),
      ]);
    }
  }, [types, currentTypes]);

  useEffect(() => {
    setToggleTypes(isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (filteredLength > 0 && filteredLength < typeList.length) {
      setTypeLabel(`${filteredLength} ${FILTER_LABELS.FILTERED_TYPES}`);
    } else {
      setTypeLabel(DEFAULT_FILTER_ITEM.name);
    }
  }, [filteredLength]);

  const handleSelectType = id => () => {
    document.getElementById(id).click();
  };

  const handleOnChange = evt => {
    const {
      target: { checked, name },
    } = evt;
    const index = typeList.map(item => item.id).indexOf(name);
    typeList[index].selected = checked;
    const selectedList = typeList.filter(item => item.selected);
    const selectAll = selectedList.length === typeList.length;
    setTypeList(
      selectAll ? [...typeList.map(item => ({ ...item, selected: false }))] : typeList,
    );
    const resolvedAll = selectAll ? [] : selectedList;
    setFilteredLength(resolvedAll.length);
    setCurrentTypes(resolvedAll);
    onTypes(KEY_TYPE, resolvedAll);
  };

  const handleToggleTypes = () => {
    setToggleTypes(!toggleTypes);
    onToggleFilters(!toggleTypes);
  };

  const handleClickOutside = () => {
    if (toggleTypes) {
      setToggleTypes(!toggleTypes);
      setCurrentTypes(initTypes);
      onTypes(KEY_TYPE, initTypes);
    }
  };

  const handleSubmitFilteredTypes = () => {
    onSubmit();
    setToggleTypes(!toggleTypes);
  };

  const handleClearFilters = () => {
    setToggleTypes(!toggleTypes);
    setFilteredLength(types.length);
    onClear();
  };

  useClickOff(listRef, handleClickOutside);

  return (
    <HomeGrid className={s.root} ref={listRef}>
      <HomeGrid className={s.items} onClick={handleToggleTypes}>
        <Typography className={s.label}>{FILTER_LABELS.TYPE}</Typography>
        <Typography noWrap className={classNames(s.bold, s.text)}>
          {typeLabel}
        </Typography>
        <IDown className={classNames(s.icon, toggleTypes && s.iActive)} />
      </HomeGrid>
      <HomeGrid className={classNames(s.content, toggleTypes && s.showContent)}>
        <HomeGrid className={s.scrollList}>
          {typeList.map(item => (
            <HomeGrid
              key={`desktop-${item.id}`}
              fluid
              className={s.row}
              onClick={handleSelectType(`desktop-${item.id}`)}
            >
              <Typography>{item.name}</Typography>
              <HomeGrid className={s.checkbox}>
                {item.selected && <CheckedIcon className={s.ibox} />}
                {!item.selected && <UncheckIcon className={s.ibox} />}
              </HomeGrid>
              <input
                id={`desktop-${item.id}`}
                name={item.id}
                type="checkbox"
                value={item.selected}
                checked={item.selected}
                onChange={handleOnChange}
                className={s.input}
              />
            </HomeGrid>
          ))}
        </HomeGrid>
        <FilterCta onSubmit={handleSubmitFilteredTypes} onCancel={handleClearFilters} />
      </HomeGrid>
    </HomeGrid>
  );
};

DesktopFilterTypes.propTypes = {
  types: arrayOf(any),
  onTypes: func.isRequired,
  onToggleFilters: func.isRequired,
  isOpen: bool,
  filteredItemsString: string.isRequired,
  onSubmit: func.isRequired,
  onClear: func.isRequired,
};

DesktopFilterTypes.defaultProps = {
  types: [],
  isOpen: false,
};

export default connect(mobileFilters.mapStateToProps)(DesktopFilterTypes);
